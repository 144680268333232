import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { LandingPage } from './pages/LandingPage'
import { Platform } from './pages/logged/Platform'
import { IMieiScambi } from './pages/logged/IMieiScambi'
import { Profilo } from './pages/logged/Profilo'
import { IlMioCoro } from './pages/logged/IlMioCoro'
import { MioAbbonamento } from './pages/logged/MioAbbonamento'
import { Aiuto } from './pages/logged/Aiuto'
import { Messaggi } from './pages/logged/Messaggi'
import { Coro } from './pages/logged/Coro'
import i18n from './i18n';

export default function App() {
  
  useEffect(() => {
    let lan = localStorage.getItem('language');
  if (lan) {
    i18n.changeLanguage(lan);
  }else{
    var lng:any = (window as any).navigator['userLanguage']|| (window as any).navigator.language;
if(lng !== 'it-IT'){
    i18n.changeLanguage('en');
}else{
    i18n.changeLanguage("it");
}
  }
}, [])
  return (
    <Router>
      <Switch>
        <Route path="/platform">
          <Platform />
        </Route>
        <Route path="/imieiscambi">
          <IMieiScambi />
        </Route>
        <Route path="/profilo">
          <Profilo />
        </Route>
        <Route path="/miocoro">
          <IlMioCoro />
        </Route>
        <Route path="/abbonamento">
          <MioAbbonamento />
        </Route>
        <Route path="/help">
          <Aiuto />
        </Route>
        <Route path="/messaggi">
          <Messaggi />
        </Route>
        <Route path="/coro/:id">
          <Coro />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router >
  );
}