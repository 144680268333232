import React from 'react';
import ReactDOM from 'react-dom';
// styles
import './index.css';
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import 'react-image-lightbox/style.css';
// import "assets/css/paper-kit.min.css";
// import "assets/css/paper-kit.css.map";
// import "assets/demo/demo.css";

import App from './App';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';

// const client = new ApolloClient({
//   uri: 'http://63.33.200.147:1337/graphql',
//   cache: new InMemoryCache()
// });
const client = new ApolloClient({
  uri: 'http://63.34.227.57:8080/graphql',
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
