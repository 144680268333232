import React, { useState } from 'react';
import { gql, ApolloClient, InMemoryCache, useApolloClient, useMutation } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  TabContent,
  CardFooter,
  TabPane,
  CustomInput, InputGroupText, ModalHeader, ModalFooter
} from 'reactstrap';
import { Formik, useFormik, Field, ErrorMessage } from 'formik';
import { FaFacebookSquare } from "react-icons/fa";
import { ImYoutube2, ImInstagram, ImFlickr3 } from "react-icons/im";
import { CgWebsite } from "react-icons/cg";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L, { LatLngTuple, LatLng } from 'leaflet';
import { redIcon, blueIcon } from './MapUtilities'
import { Header } from "../../components/Header"
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
const defaultLatLng: LatLngTuple = [39.217162, 9.113405];

let uri
if (process.env.NODE_ENV === "development") {
  uri = 'http://63.34.227.57:8080/graphql'
} else if (process.env.NODE_ENV === 'production') {
  uri = "https://choirex-backend.radixcloud.org/graphql"
}
const client = new ApolloClient({
  uri: uri,
  cache: new InMemoryCache()
});

export const CREATE_CHOIR = gql
  `mutation createChoir(
  $nome: String,
  $latitudine: Float,
  $longitudine: Float,
  $numero_cantori: String,
  $tipo_scambio: JSON,
  $tipologia_coro: String,
  $tipologia_voci: JSON,
  $dove_trovarci: String
){createChoir(
  input: {
      data: {
        nome: $nome
        latitudine: $latitudine 
        longitudine: $longitudine
        numero_cantori: $numero_cantori
        tipo_scambio: $tipo_scambio
        tipologia_coro: $tipologia_coro
        tipologia_voci: $tipologia_voci
        dove_trovarci: $dove_trovarci
      }
    }
){choir{id}}
}`

export const UPDATE_CHOIR = gql
  `mutation updateChoir(
  $id: Int!,
  $biografia: String,
  $esperienza: String
){updateChoir(
  input: {
    data: {
      id: $id
      biografia: $biografia
      esperienza: $esperienza
    }
  }
){choir{id}}}`

export const IlMioCoro = () => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const [markerPosition, setMarkerPosition] = useState({ lat: defaultLatLng[0], lng: defaultLatLng[1] });

  const [coro, setCoro] = useState('0')
  const [addChoir, { data }] = useMutation(CREATE_CHOIR);
  const [updateChoir] = useMutation(UPDATE_CHOIR);
  const [inputCoristi, setInputCoristi] = useState({} as any)
  const [inputTipoVoci, setInputTipoVoci] = useState({} as any)
  const [inputRepertorio, setInputRepertorio] = useState({} as any)
  const [inputAlloggio, setInputAlloggio] = useState({} as any)
  const [inputVitto, setInputVitto] = useState({} as any)
  const [inputLuoghi, setInputLuoghi] = useState({} as any)
  const [inputAttrezzature, setInputAttrezzature] = useState({} as any)
  const { t, i18n } = useTranslation('miocoro', { useSuspense: false });
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [modal, setModal] = useState(true);
  const toggleModal = () => setModal(!modal);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const addPosition = (e: any) => {
    console.log("add position", e)
    // let markers = { lat: 39.217162, lng: 9.113405 }
    // markers.pop();
    let markers = e.latlng
    // markers.lng=e.latlng.lng
    setMarkerPosition(markers as any)
    console.log("add position marker", markerPosition.lat)
  }

  //   const upload = (e: any) => {

  //     const formElement = document.getElementById('form');
  //     e.preventDefault();
  //     const request = new XMLHttpRequest();
  //     request.open('POST', '/upload');
  //     if (formElement)
  //       request.send(new FormData(formElement));

  //     console.log("upload ok", request, formElement)

  const tipologia_voci = [
    { value: 'misto', label: `${t('misto')}` },
    { value: 'voci_pari_maschili', label: `${t('voci_pari_M')}` },
    { value: 'voci_pari_femminili', label: `${t('voci_pari_F')}` },
    { value: 'voci_bianche', label: `${t('voci_bianche')}` },
    { value: 'giovanili', label: `${t('giovanili')}` },

  ]

  const repertorio = [
    { value: 'polifonia_sacra_e_profana_di_varie_epoche_e_stili', label: `${t('polifonia_sacra_e_profana_di_varie_epoche_e_stili')}` },
    { value: 'musica_popolare', label: `${t('musica_popolare')}` },
    { value: 'lirica', label: `${t('lirica')}` },
    { value: 'musica_liturgica', label: `${t('musica_liturgica')}` },
    { value: 'musica_da_film', label: `${t('musica_da_film')}` },
    { value: 'pop', label: `${t('pop')}` },
    { value: 'gospel', label: `${t('gospel')}` },
    { value: 'nessun_repertorio_prevalente', label: `${t('nessun_repertorio_prevalente')}` },
    { value: 'altro', label: `${t('altro')}` }
  ]

  const scambio_options = [
    { value: 'Reciproco', label: `${t('reciproco')}` },
    { value: 'Non Reciproco', label: `${t('non_reciproco')}` },
    { value: 'Artistico', label: `${t('artistico')}` }
  ]

  const esperienza_coro = [
    { value: 'Meno di un anno', label: `${t('meno_un_anno')}` },
    { value: 'Da uno a tre anni', label: `${t('da_1_a_3')}` },
    { value: 'Da tre anni a cinque anni', label: `${t('da_3_a_5')}` },
    { value: 'Oltre i cinque anni', label: `${t('oltre_5')}` }
  ]
  const numero_coristi = [
    { value: 'Meno di 10', label: `${t('meno_di_10')}` },
    { value: 'Tra 10 e 25', label: `${t('tra_10_e_25')}` },
    { value: 'Tra 25 e 40', label: `${t('tra_25_e_40')}` },
    { value: 'Oltre 40', label: `${t('oltre_40')}` }
  ]

  const stati_options = [
    { value: 'Italia', label: `${t('italia')}` },
    { value: 'Francia', label: `${t('francia')}` },
    { value: 'Regno Unito', label: `${t('regno_unito')}` },
    { value: 'Germania', label: `${t('germania')}` },
    { value: 'Spagna', label: `${t('spagna')}` }
  ]
  const viaggio_options = [
    { value: 'Ti aiuto ad organizzare', label: `${t('aiuto_organizzare')}` },
    { value: 'Sei autonomo nella organizzazione', label: `${t('organizzazione_autonoma')}` }
  ]
  const alloggio_options = [
    { value: 'Albergo o b&b', label: `${t('albergo_beb')}` },
    { value: 'Autonomo', label: `${t('autonomo')}` },
    { value: 'Convenzioni', label: `${t('convenzioni')}` },
    { value: 'Altro', label: `${t('altro_digita')}`, isDisabled: true }
  ]
  const vitto_options = [
    { value: 'Ristorante', label: `${t('ristorante')}` },
    { value: 'Home restaurant in famiglia', label: `${t('home_famiglia')}` },
    { value: 'Home restaurant di gruppo', label: `${t('home_gruppo')}` },
    { value: 'Autonomo', label: `${t('autonomo')}` },
    { value: 'Convenzioni', label: `${t('convenzioni')}` },
    { value: 'Altro', label: `${t('altro_digita')}`, isDisabled: true }
  ]
  const trasporti_options = [
    { value: 'Garantito', label: `${t('garantito')}` },
    { value: 'Autonomo', label: `${t('autonomo')}` }
  ]
  const nConcerti_options = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: 'Piú di tre', label: `${t('piu_di_3')}` },
  ]
  const luoghi_options = [
    { value: 'Chiese', label: `${t('chiese')}` },
    { value: 'Teatri', label: `${t('teatri')}` },
    { value: 'Sale da concerto', label: `${t('sale_concerto')}` },
    { value: 'Spazi all’aperto', label: `${t('spazi_aperto')}` },
    { value: 'Altri spazi (digita e premi invio)', label: `${t('altri_spazi')}`, isDisabled: true }
  ]
  const attrezzature_options = [
    { value: 'Ripresa audio', label: `${t('ripresa_audio')}` },
    { value: 'Ripresa video', label: `${t('ripresa_video')}` },
    { value: 'Impianto audio', label: `${t('impianto_audio')}` },
    { value: 'Pianoforte elettrico', label: `${t('pianoforte')}` },
    { value: 'Leggii', label: `${t('leggii')}` },
    { value: 'Fari', label: `${t('fari')}` },
    { value: 'Altri strumenti (specifica)', label: `${t('altri_strumenti')}`, isDisabled: true }
  ]
  const servizi_aggiuntivi_options = [
    { value: 'Visite guidate della città', label: `${t('visite_guidate')}` },
    { value: 'Progetti corali specifici', label: `${t('progetti_corali')}` },
    { value: 'Laboratori didattici', label: `${t('lab_didattici')}` }
  ]
  const lingua_inserimento = [
    { value: 'italiano', label: `${t('italiano')}` },
    { value: 'english', label: `${t('english')}` }
  ]
  const steps = [
    {
      selector: '.fifth-step',
      content: <p>{t("step1")}</p>,
      // content: <p>Ecco come puoi presentare <span className="text-success"><strong>il tuo coro</strong></span></p>,
      action: () => toggle('1')
    },
    {
      selector: '.fifth-step1',
      content: <p>{t("step2")}</p>,
      // content: <p>Aggiungi importanti <span className="text-success"><strong>informazioni</strong></span></p>,
      action: () => toggle('1')
    },
    {
      selector: '.sixth-step',
      content: <p>{t("step3")}</p>,
      // content: <p>Racconta la storia e descrivi <span className="text-success"><strong>il tuo coro</strong></span></p>,
      action: () => toggle('2')
    }, {
      selector: '.sixth-step1',
      content: <p>{t("step4")}</p>,
      // content: <p>Aggiungi le pagine social e i contenuti multimediali</p>,
      action: () => toggle('2')
    },
    {
      selector: '.seventh-step',
      content: <p>{t("step5")}</p>,
      // content: <p>Presentati per uno <span className="text-success"><strong>scambio</strong></span></p>,
      action: () => toggle('3')
    },
    {
      selector: '.seventh-step1',
      content: <p>{t("step6")}</p>,
      // content: <p>Descrivi la tua proposta di <span className="text-success"><strong>ospitalità</strong></span></p>,
      action: () => toggle('3')
    },
    {
      selector: '.eighth-step',
      content: <p></p>,
      action: () => {
        const target=document.querySelector(".tour")
        enableBodyScroll(target as any);
        history.push("/messaggi")
      }
    },
  ]
  const history = useHistory();

  const disableBody = (target: any) => disableBodyScroll(target)
  const enableBody = (target: any) => enableBodyScroll(target)
  return (
    <>
      <Tour
        className="tour"
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        accentColor="#00A0A4"
        maskSpace={32}
        rounded={32}
      />
      <Header />
      <Container fluid style={{ minHeight: '100vh', backgroundColor: '#CCCCCC' }}>
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                <Card className="no-transition mt-4">
                  <CardHeader><h6>{t('presentati')}</h6></CardHeader>
                  {/* <CardTitle>PRESENTATI</CardTitle>
                 Iniziamo a conoscere meglio il tuo coro */}
                  <CardBody >
                    <Formik
                      initialValues={{
                        name: '',
                        email: '',
                        telefono: '',
                        esperienza: '',
                        dove_trovarci: '',
                        coristi: '',
                        // tipologia_voci: [],
                        // tipologia_coro: '',
                        // tipo_scambio: []
                      }}
                      onSubmit={async values => {
                        // console.log("register values: ", values, JSON.stringify(values.tipologia_voci))
                        // const result = await addChoir({
                        //   variables: {
                        //     nome: values.name,
                        //     latitudine: markerPosition.lat,
                        //     longitudine: markerPosition.lng,
                        //     numero_cantori: values.coristi,
                        //     tipo_scambio: JSON.stringify(values.tipo_scambio),
                        //     tipologia_coro: values.tipologia_coro,
                        //     tipologia_voci: JSON.stringify(values.tipologia_voci),
                        //     dove_trovarci: values.dove_trovarci
                        //   }
                        // });
                        // console.log('after result: ', result)
                        // setCoro(result.data.createChoir.choir.id)
                        toggle('2');
                      }}
                    >
                      {({ errors, touched, handleSubmit }) => (
                        <Form name="create_choir" method="post" onSubmit={
                          (e: any) => {
                            e.preventDefault();
                            handleSubmit();
                          }
                        } >
                          <div className='fifth-step'>
                            <FormGroup>
                              <Label for="n_concerti" className='font-weight-bold'>{t('lingua_inserimento')}</Label>
                              <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" options={lingua_inserimento} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword" className='font-weight-bold'>{t('inserisci_nome_coro')}</Label>
                              <Input type="text" name="name" tag={Field} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword" className='font-weight-bold'>{t('email')}</Label>
                              <Input type="text" name="email" tag={Field} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword" className='font-weight-bold'>{t('telefono')}</Label>
                              <Input type="number" name="telefono" tag={Field} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="exampleSelect" className='font-weight-bold'
                              >{t('esperienza_coro')}</Label>
                              <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('esperienza_coro_ph')} options={esperienza_coro} />
                            </FormGroup>
                            <FormGroup>
                              <Label for="exampleSelect" className='font-weight-bold'>{t('dove_ti_trovi')}</Label>
                              <Input type="text" id='dove_trovarci' />
                              <Map center={L.latLng(defaultLatLng)} zoom={12} style={{ height: 200 }}
                                onClick={(e: any) => { addPosition(e) }}>
                                <TileLayer
                                  url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                  attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                {markerPosition ? (
                                  <Marker
                                    key={`marker`}
                                    icon={blueIcon}
                                    position={L.latLng(markerPosition.lat, markerPosition.lng)}
                                    draggable={false}
                                  >
                                  </Marker>) : (<></>)}
                              </Map>
                            </FormGroup>
                          </div>
                          <div className='fifth-step1'>
                            <FormGroup>
                              <Label for="examplePassword" className='font-weight-bold'>{t('n_cantori')}</Label>
                              <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('n_cantori_ph')} options={numero_coristi} onChange={(e) => setInputCoristi(e)} />
                              {inputCoristi.value == ("Oltre 40") ? (
                                <Input type="number" name="cantori" placeholder={t('ins_n-cantori')} tag={Field} />
                              ) : (<></>)}
                            </FormGroup>
                            <FormGroup>
                              <Label for="tipologia_voci" className='font-weight-bold'>{t('tipo_voci')}</Label>
                              <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('tipo_voci_ph')} options={tipologia_voci} onChange={(e) => setInputTipoVoci(e)} />
                              {inputTipoVoci.value == ("Altro") ? (
                                <Input type="text" name="tipo_voci" placeholder={t('altro')} tag={Field} />
                              ) : (<></>)}
                            </FormGroup>
                            <FormGroup>
                              <Label for="example\Select" className='font-weight-bold'>{t('repertorio')}</Label>
                              <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('repertorio_ph')} options={repertorio} onChange={(e) => setInputRepertorio(e)} />
                              {inputRepertorio.value == ("Altro") ? (
                                <Input type="text" name="tipologia_coro" placeholder={t('altro')} tag={Field} />
                              ) : (<></>)}
                            </FormGroup>
                          </div>
                          <Button type="submit">{t('salva_avanti')}</Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </ TabPane>
              {/* SECONDA PARTE */}
              <TabPane tabId='2'>
                <Card className="no-transition mt-4" style={{ paddingBottom: '40px' }}>
                  <CardHeader><h6>{t('raccontati')}</h6></CardHeader>
                  <CardBody >
                    <Formik
                      initialValues={{
                        biografia: '',
                        esperienza: ''
                      }}
                      onSubmit={async values => {
                        console.log("register values: ", values)
                        // const result = await updateChoir({
                        //   variables: {
                        //     id: 11,
                        //     biografia: values.biografia,
                        //     esperienza: values.esperienza
                        //   }
                        // });
                        // console.log('after result: ', result)
                        toggle('3');
                      }}
                    >
                      {({ errors, touched, handleSubmit }) => (
                        <Form name="upload_choir" method="post" onSubmit={
                          (e: any) => {
                            e.preventDefault();
                            handleSubmit();
                          }
                        } >
                          <div className='sixth-step'>
                            <FormGroup>
                              <Label className='font-weight-bold'>{t('storia_coro')}</Label>
                              <Input type="textarea" name="biografia" id="exampleText" maxlength="250" />
                            </FormGroup>
                            <FormGroup>
                              <CardText className='font-weight-bold'>{t('descrivi_coro')}</CardText>
                              <FormGroup><Label className='font-weight-bold'>{t('aggettivi')}</Label><Input type="text" name="text" id="exampleText" placeholder='' /><br />
                                <Input type="textarea" name="text" id="exampleText" placeholder={t('add_comment')} /></FormGroup>
                              <FormGroup><Label className='font-weight-bold'>{t('cantori')}</Label><Input type="textarea" name="text" id="exampleText" placeholder='' /></FormGroup>
                              <FormGroup><Label className='font-weight-bold'>{t('valori_ideali')}</Label><Input type="textarea" name="text" id="exampleText" placeholder='' /></FormGroup>
                            </FormGroup>
                          </div>
                          <div className='sixth-step1'>
                            <FormGroup>
                              <Label for="social" className='font-weight-bold'>{t('ti_troviamo_su')}</Label>
                              <Row><InputGroup><InputGroupAddon addonType="prepend"><InputGroupText><CgWebsite size='1.5em' /></InputGroupText></InputGroupAddon><Input type="text" name="text" id="exampleText" placeholder={t('sito_web')} /></InputGroup></Row>
                              <Row><InputGroup><InputGroupAddon addonType="prepend"><InputGroupText><FaFacebookSquare size='1.5em' /></InputGroupText></InputGroupAddon><Input type="text" name="text" id="exampleText" placeholder='Facebook' /></InputGroup></Row>
                              <Row><InputGroup><InputGroupAddon addonType="prepend"><InputGroupText><ImYoutube2 size='1.5em' /></InputGroupText></InputGroupAddon><Input type="text" name="text" id="exampleText" placeholder='Youtube' /></InputGroup></Row>
                              <Row><InputGroup><InputGroupAddon addonType="prepend"><InputGroupText><ImInstagram size='1.5em' /></InputGroupText></InputGroupAddon><Input type="text" name="text" id="exampleText" placeholder='Instagram' /></InputGroup></Row>
                              <Row><InputGroup><InputGroupAddon addonType="prepend"><InputGroupText><ImFlickr3 size='1.5em' /></InputGroupText></InputGroupAddon><Input type="text" name="text" id="exampleText" placeholder='Flickr' /></InputGroup></Row>
                              <Row><InputGroup><InputGroupAddon addonType="prepend"><InputGroupText></InputGroupText></InputGroupAddon><Input type="text" name="text" id="exampleText" placeholder={t('altro')} /></InputGroup></Row>
                            </FormGroup>
                            <FormGroup>
                              <Label className='font-weight-bold'>{t('scatti')}</Label>
                              <input type="file" name="foto" id='foto' />
                              <input type="text" hidden name="ref" value="choir" />
                              <input type="text" hidden name="refId" value="11" />
                              <input type="text" hidden name="field" value="pictures" />
                              <Button >{t('upload')}</Button>
                            </FormGroup>
                            <FormGroup>
                              <Label className='font-weight-bold'>{t('video')}</Label>
                              <input type="file" name="video" id="video" />
                              <input type="text" hidden name="ref" value="restaurant" />
                              <input type="text" hidden name="refId" value="5c126648c7415f0c0ef1bccd" />
                              <input type="text" hidden name="field" value="cover" />
                              <Button >{t('upload')}</Button>
                            </FormGroup>
                          </div>
                          <Button onClick={() => toggle('1')}>{t('indietro')}</Button>
                          <Button type="submit">{t('salva_avanti')}</Button>

                        </Form>)}
                    </Formik>
                  </CardBody>

                </Card>
              </ TabPane>
              <TabPane tabId='3'>
                <Card className="no-transition mt-4" style={{ paddingBottom: '40px' }}>
                  <CardHeader><h6>{t('proposta_scambio')}</h6></CardHeader>
                  <CardBody>
                    <Formik
                      initialValues={{
                        name: '',
                        coristi: '',
                        tipologia_voci: [],
                        tipologia_coro: '',
                        dove_trovarci: '',
                        tipo_scambio: []
                      }}
                      onSubmit={async values => {
                        console.log("register values: ", values, JSON.stringify(values.tipologia_voci))
                        const result = await addChoir({
                          variables: {
                            nome: values.name,
                            latitudine: markerPosition.lat,
                            longitudine: markerPosition.lng,
                            numero_cantori: values.coristi,
                            tipo_scambio: JSON.stringify(values.tipo_scambio),
                            tipologia_coro: values.tipologia_coro,
                            tipologia_voci: JSON.stringify(values.tipologia_voci),
                            dove_trovarci: values.dove_trovarci
                          }
                        });
                        console.log('after result: ', result)
                        setCoro(result.data.createChoir.choir.id)
                        toggle('2');
                      }}>
                      <Form>
                        <div className='seventh-step'>
                          <FormGroup>
                            <Label for="tipo" id="tipo_scambio" className='font-weight-bold'>{t('tipo_scambio_interessato')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('tipo_scambio_ph')} isMulti options={scambio_options} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="tipo" id="ospitato" className='font-weight-bold'>{t('periodo_ospitato')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('periodo_ospitato_are_ph')} isMulti />
                          </FormGroup>
                          <FormGroup>
                            <Label for="tipo" id="stati" className='font-weight-bold'>{t('dove_andare')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('dove_andare_ph')} isMulti options={stati_options} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="tipo" id="ospitare" className='font-weight-bold'>{t('periodo_ospitare')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('periodo_ospitato_are_ph')} isMulti />
                          </FormGroup>
                        </div>
                        <div className='seventh-step1'>
                          <CardText className='font-weight-bold'>{t('proposta_ospitalita')}</CardText>
                          <FormGroup>
                            <Label for="tipo_viaggio" className='font-weight-bold'>{t('viaggio')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" options={viaggio_options} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="alloggio" className='font-weight-bold'>{t('alloggio')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" isMulti options={alloggio_options} onChange={(e) => setInputAlloggio(e)} />
                            {inputAlloggio.value == ("Altro") ? (
                              <Input type="text" name="alloggio" placeholder={t('altro')} tag={Field} />
                            ) : (<></>)}
                          </FormGroup>
                          <FormGroup>
                            <Label for="vitto" className='font-weight-bold'>{t('vitto')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" isMulti options={vitto_options} onChange={(e) => setInputVitto(e)} />
                            {inputVitto.value == ("Altro") ? (
                              <Input type="text" name="vitto" placeholder={t('altro')} tag={Field} />
                            ) : (<></>)}
                          </FormGroup>
                          <FormGroup>
                            <Label for="tipo_trasporti" className='font-weight-bold'>{t('trasporti_luogo')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" isMulti options={trasporti_options} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="n_concerti" className='font-weight-bold'>{t('quanti_concerti')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" options={nConcerti_options} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="luoghi" className='font-weight-bold'>{t('quali_luoghi')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" isMulti options={luoghi_options} onChange={(e) => setInputLuoghi(e)} />
                            {inputLuoghi.value == ("Altri spazi (specifica)") ? (
                              <Input type="text" name="luoghi" placeholder={t('specifica')} tag={Field} />
                            ) : (<></>)}
                          </FormGroup>
                          <FormGroup>
                            <Label for="attrezzature" className='font-weight-bold'>{t('attrezature')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" isMulti options={attrezzature_options} onChange={(e) => setInputAttrezzature(e)} />
                            {inputAttrezzature.value == ("Altri strumenti (specifica)") ? (
                              <Input type="text" name="attrezzature" placeholder={t('specifica')} tag={Field} />
                            ) : (<></>)}
                          </FormGroup>
                          <FormGroup>
                            <Label for="servizi_aggiuntivi" className='font-weight-bold'>{t('serv_aggiuntivi')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder="" isMulti options={servizi_aggiuntivi_options} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="lingue" className='font-weight-bold'>{t('quali_lingue')}</Label>
                            <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('ins_lingue')} isMulti />
                          </FormGroup>
                          <FormGroup>
                            <Label for="proposta" className='font-weight-bold'>{t('descr_proposta')}</Label>
                            <Input type="textarea" name="proposta" />
                          </FormGroup>
                        </div>
                      </Form>
                    </Formik>
                  </CardBody>
                  <CardFooter>
                    <Button onClick={() => toggle('2')}>{t('indietro')}</Button>
                    <Button href="/platform" >{t('salva_chiudi')}</Button>
                  </CardFooter>
                </Card>
              </ TabPane>
            </TabContent>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggleModal} className="" >
          <ModalHeader>{t('form_non_reale')}</ModalHeader>
          <ModalFooter>
            <Button color="primary" onClick={toggleModal}>{t('accetto')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </Container >

      <div className="shadow-lg" style={{ position: 'fixed', borderRadius: 32, bottom: 10, right: 10, zIndex: 10000, backgroundColor: 'transparent' }}>
        <Button size="lg" className="shadow-lg btn-round mb-0 mt-0 " style={{ height: 60 }} color="success" onClick={() => {
          setIsTourOpen(true);
        }}>{t('continua')}</Button>
      </div>
    </>
  )
}