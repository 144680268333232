import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  TabContent,
  CardFooter,
  TabPane,
  CustomInput
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { Header } from "../../components/Header"
import { useTranslation } from 'react-i18next';


export const Profilo = () => {
  const [choirs, setChoirs] = useState([] as any)
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const { t, i18n } = useTranslation('profilo', { useSuspense: false });


  return (
    <>
      <Header />

      <Container fluid style={{ minHeight: '100vh', backgroundColor: '#CCCCCC' }}>
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <Card className="mt-4" >
              <CardHeader><h6>{t('registrati')}</h6></CardHeader>
              {/* <CardTitle>
                  Inserisci i tuoi dati</CardTitle> */}
              <CardBody>
                <Row>
                  <Col xs='3'>
                    <CardImg top src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="Card image cap" />
                  </Col>
                  <Col xs='9'>
                    <Form>
                      <FormGroup row>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder={t('nome_cognome')}
                        />
                      </FormGroup>
                      <FormGroup row>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder={t('email')}
                        />
                      </FormGroup>
                      <FormGroup row>
                        <Input
                          type="date"
                          name="datanascita"
                          id="datanascita"
                          placeholder={t('data_nascita')}
                        />
                      </FormGroup>
                      <FormGroup row>
                        <Input
                          type="password"
                          name="pw"
                          id="pw"
                          placeholder={t('pw_attuale')}
                        />
                      </FormGroup>
                      <FormGroup row>
                        <Input
                          type="password"
                          name="pw"
                          id="pw"
                          placeholder={t('conferma_pw')}
                        />
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={12}>
                          <CardText>{t('ti_invieremo')}</CardText>
                          <Row><Label><Input name="promotions" type="checkbox" value="promotion_off" />{t('non_voglio_ricevere')}</Label></Row>
                          <Row><Label check><Input name="promotions" type="checkbox" value="promotion_off" />{t('accetto_condiz')}</Label></Row>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button href="/miocoro">{t('salva_avanti')}</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  )
}