import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  Badge, CardLink, CarouselItem, CarouselCaption
} from 'reactstrap';
import { FaFacebookSquare, FaPlane, FaHotel, FaWifi, FaParking, FaDog, FaFlickr } from "react-icons/fa";
import { ImYoutube2, ImInstagram } from "react-icons/im";
import { RiArrowUpDownLine } from "react-icons/ri";
import { MdRestaurant } from "react-icons/md";
import 'react-calendar/dist/Calendar.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import getChoir from '../../graphql/getChoir.js'
import Slider from "react-slick";

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Header } from "../../components/Header"
import ReactPlayer from 'react-player'
import AliceCarousel from 'react-alice-carousel';
import ModalImage from "react-modal-image";
import { ChoirDetail } from '../../components/ChoirDetail';
import { uri, url_img } from '../../backend';
import Lightbox from 'react-image-lightbox';
import { useTranslation } from 'react-i18next';


const localizer = momentLocalizer(moment)
const client = new ApolloClient({ uri: uri, cache: new InMemoryCache() });

export const Coro = () => {
  const index = parseInt(window.location.pathname.slice(6))
  const date = { id: 1, allDay: true, start: new Date('2020-10-01T10:00:00.000Z'), end: new Date('2020-10-05T10:00:00.000Z') }
  const date1 = { id: 1, allDay: true, start: new Date('2020-10-10T10:00:00.000Z'), end: new Date('2020-10-15T10:00:00.000Z') }
  const events = [date, date1]
  const [choir, setChoir] = React.useState({} as any)
  const [choirData, setChoirData] = React.useState([] as any)
  const [items, setItems] = React.useState([] as any)
  const [zoomOpen, setZoomOpen] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState("")
  const { t, i18n } = useTranslation('coro', { useSuspense: false });

  React.useEffect(() => {

    const _ = (
      async () => {
        const result = await client.query({
          query: gql(getChoir),
          variables: { id: index }
        })
        const reservations = result.data.reservations && result.data.reservations.map((res: any) => {
          if (res.choir && res.choir.id == index) {
            return res
          }
        })
        console.log('with result: ', result)
        setChoir(result.data.choir);

        setItems(result.data.choir.images ? result.data.choir.images.map((img: any) => {

          console.log('with images:');
          let height
          let width
          if (img.formats.medium) {
            height = img.formats.medium.height;
            width = img.formats.medium.width;
          } else {
            height = img.formats.small.height;
            width = img.formats.small.width;
          }
          const newWidth = width * 360 / height;
          console.log('with ', height, width, 360, newWidth);
          return (
            <img width={newWidth} height={360} style={{ paddingRight: 16 }} src={`${url_img}${img.url}`} alt={`${url_img}${img.url}`} onClick={() => {
              setSelectedImage(`${url_img}${img.url}`);
              setZoomOpen(true);
              console.log('after zoomOpen with ', `${url_img}${img.url}`)
            }} />
          )

        }) : [])

        console.log('with items: ', items)
      }
    )()
    return () => {
    }
  }, [])

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    <>
      <Header />
      <Container fluid style={{ backgroundColor: '#CCCCCC', paddingTop: 10, minHeight: '100vh' }}>
        <Card style={{ padding: 10 }} className="no-transition">
          <AliceCarousel animationDuration={2000} autoPlayStrategy={"all"} autoPlay={true} infinite={true} disableButtonsControls={true}  autoWidth mouseTracking items={items} />
        </Card>

        <Row>
          <Col xs='12' md='8'>
            <ChoirDetail choir={choir} />
          </Col>

          <Col xs='12' md='4'>
            <Button className="btn-round mb-3 mt-0" style={{}} color="success" block>{t('contatta')}</Button>
            {(choir.videos ? (
              <Card className="no-transition">
                <CardHeader>{t('video')}</CardHeader>
                <Row>
                  <Col className="col-md-12 col-md-offset-6 mb-4">
                    <div className='player-wrapper'>
                      <ReactPlayer width='100%' height='100%' controls={true} className='react-player' url={`${url_img}${choir.videos.url}`} />
                    </div>
                  </Col>
                </Row>
              </Card>
            ) : (
                <></>
              ))}
            <Card className="no-transition">
              <CardHeader>{t('calendario')}</CardHeader>
              <div>
                <Calendar
                  localizer={localizer}
                  events={events}
                  step={60}
                  max={moment().add(365, 'days').toDate()}
                  showMultiDayTimes
                  style={{ height: '40vh' }}
                  views={['month']}
                  defaultDate={moment().toDate()}
                  startAccessor="start"
                  endAccessor="end"
                  className="mb-4"
                /></div>
            </Card>
          </Col>
        </Row>

      </Container>
      {
        zoomOpen && (
          <Lightbox
            mainSrc={selectedImage}
            onCloseRequest={() => setZoomOpen(false)}
          />
        )
      }
    </>
  )
}