import React, { useState } from 'react';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container, Badge,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal, Tooltip, Popover, PopoverHeader, ModalHeader, ModalBody, ModalFooter, InputGroupText, TabPane, TabContent
} from 'reactstrap';
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

var showdown = require('showdown')
var myext = {
  type: 'output',
  filter: function (text: any, converter: any, options: any) {
    console.log('in filter with: ', text)
    return text.replace(/<li>/g, '<li><i className="bx bx-check-double"></i>');
  }
};
showdown.extension('myext', myext);
const converter = new showdown.Converter({ extensions: [myext] });
export const ChoirDetail = ({ choir }: any) => {

  const [choirData, setChoirData] = React.useState([] as any)
  const { t, i18n } = useTranslation('coro', { useSuspense: false });
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab:any) => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  React.useEffect(() => {

    console.log('after with choir details: ', choir);
    let choirLangData
    if (choir && choir.localized_data) {
      if (choir.localized_data.length > 1) {
        if (navigator.language == 'it-IT') {
          choirLangData = choir.localized_data.find((el: any) =>
            el.language == 'italiano')
        } else {
          choirLangData = choir.localized_data.find((el: any) =>
            el.language == 'english')
        }
      } else {
        choirLangData = choir.localized_data[0]
      }
      console.log("choir lang", choirLangData)
      setChoirData(choirLangData)
    }

    return () => { }
  }, [choir])
  console.log("prova 123", choirData.description)
  return (
    <Card className="no-transition">
      <CardHeader><h4 className="float-left" style={{ margin: 0 }}>{choir.nome}{'   '}</h4>
        <>
          <div className="float-right">
            {choir.localized_data ? (
              <>{choir.localized_data.length > 1 ? (
                <>
                  {choir.localized_data.map((choirLoc: any) => {

                    const countryCode = choirLoc.language == "italiano" ? "IT" : "US"
                    return (
                      <ReactCountryFlag countryCode={countryCode}
                        style={{
                          fontSize: '1.5em',
                          lineHeight: '1.5em',
                          cursor: 'pointer',
                          marginRight: 10
                        }}
                        onClick={() => { setChoirData(choirLoc) }}
                      />
                    )
                  })}
                </>
              ) : (<></>)}</>) : (<></>)}
          </div>
        </>
       
      </CardHeader>
      <CardHeader >
      <Nav tabs  style={{cursor:"pointer"}}>
        <NavItem>
          <NavLink 
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            {t('ilcoro')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            {t('chisiamo')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            {t('loscambio')}
          </NavLink>
        </NavItem>
      </Nav>
      </CardHeader>
      <CardBody>
        <Container>
        <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
        <Row className="mb-2">
            <Col md={4}><h6>{t('email')}</h6></Col>
            <Col md={8}><p>{choir.email}</p></Col>
            </Row>
            <Row className="mb-2">
            <Col md={4}><h6>{t('telefono')}</h6></Col>
            <Col md={8}><p>{choir.telefono}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('anni_attivita')}</h6></Col>
            <Col md={8}><p>{choir.anni_attivita}</p></Col>
            </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('numero_cantori')}</h6></Col>
            <Col md={8}><p>{choir.numero_cantori}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('indirizzo')}</h6></Col>
            <Col md={8}><p>{choir.indirizzo}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('tipologia_voci')}</h6></Col>
            <Col md={8}><p>{choirData.tipologia_voci}</p></Col>
            </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('tipologia_coro')}</h6></Col>
            <Col md={8}><p>{choirData.tipologia_coro}</p></Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
        <Row className="">
            <Col>
              <h6>{t('nostra_storia')}</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <p className="text-justify"><span dangerouslySetInnerHTML={{ __html: converter.makeHtml(choirData.nostra_storia != undefined ? choirData.nostra_storia : "") }} />
           </p></Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col>
              <h6>{t('trovate_su')}</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('sito_web')}</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.sito_web}`}>{choir.sito_web}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Facebook</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.facebook}`}>{choir.facebook}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Instagram</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.instagram}`}>{choir.instagram}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Flickr</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.flickr}`}>{choir.flickr}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Youtube</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.youtube}`}>{choir.youtube}</a></p></Col>
          </Row>
          <hr />
          <Row className="">
            <Col>
              <h6>{t('descrizione')}</h6>
            </Col>
          </Row>
          {choirData.description ? (
            <Col>
              <Row className="mb-2">
                <Col>
                  <h6>{t('3_aggettivi')}</h6>
                  <p className="text-justify">{choirData.description.aggettivi}</p><br/>
                  <p className="text-justify">{choirData.description.commento}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <h6>{t('cantori')}</h6>
                  <p className="text-justify">{choirData.description.cantori}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <h6>{t('valori_ideali')}</h6>
                  <p className="text-justify">{choirData.description.valori_ideali}</p>
                </Col>
              </Row>
            </Col>
          ) : (<></>)}          
        </TabPane>
        <TabPane tabId="3">
        <hr />
          <Row className="mb-2">
            <Col md={4}><h6>{t('tipo_scambio')}</h6></Col>
            <Col md={8}><p>{choirData.tipo_scambio && choirData.tipo_scambio.map((s: any) => (<span>{s.tipologia_scambio} /</span>))}</p></Col>
            </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('periodo_ospitato')}</h6></Col>
            <Col md={8}>{choirData.date_ospitato && choirData.date_ospitato.map((p: any) => (<p>{p.mese} {p.anno}</p>))}</Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('mete_preferite')}</h6></Col>
            <Col md={8}><p>{choirData.destinazioni && choirData.destinazioni.map((d: any) => (<span> {d.destinazioni_preferite} /</span>))}</p></Col>
            </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('periodo_ospitare')}</h6></Col>
            <Col md={8}>{choirData.date_ospitare && choirData.date_ospitare.map((p: any) => (<p>{p.mese} {p.anno}</p>))}</Col>
          </Row>
          <hr />
          {/* <Row className="mb-2">
            <Col>
              <h6>{t('proposta_ospitalita')}</h6>
            </Col>
          </Row> */}
          <Row className="mb-2">
            <Col md={4}><h6>{t('viaggio')}</h6></Col>
            <Col>
              <p className="text-justify">{choirData.viaggio}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('alloggio')}</h6></Col>
            <Col><p>{choirData.alloggio && choirData.alloggio.map((a: any) => (<span>{a.alloggio} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('vitto')}</h6></Col>
            <Col><p>{choirData.vitto && choirData.vitto.map((v: any) => (<span>{v.vitto} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('trasporto')}</h6></Col>
            <Col><p>{choirData.trasporto && choirData.trasporto.map((t: any) => (<span>{t.trasporto} / </span>))}</p></Col>
          </Row>
          {choirData.numero_concerti ? (
            <Row className="mb-2">
            <Col md={4}><h6>{t('concerti')}</h6></Col>
            <Col md={8}><p>{choirData.numero_concerti}</p></Col>
            </Row>
          ):(<></>)}
          <Row className="mb-2">
            <Col md={4}><h6>{t('l_concerti')}</h6></Col>
            <Col md={8}><p>{choirData.luoghi_concerti && choirData.luoghi_concerti.map((l: any) => (<span>{l.luoghi_concerti} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('attrezzature')}</h6></Col>
            <Col><p>{choirData.attrezzatura && choirData.attrezzatura.map((a: any) => (<span>{a.attrezzatura} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('servizi_agg')}</h6></Col>
            <Col><p>{choirData.servizi_aggiuntivi && choirData.servizi_aggiuntivi.map((s: any) => (<span>{s.servizi_aggiuntivi} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}><h6>{t('lingue_parlate')}</h6></Col>
            <Col><p>{choirData.lingue_parlate && choirData.lingue_parlate.map((l: any) => (<span>{l.lingue_parlate} / </span>))}</p></Col>
          </Row>
          <hr />
          <Row className="">
            <Col>
              <h6>{t('proposta_scambio')}</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <p className="text-justify">{choirData.proposta_scambio}</p>
            </Col>
          </Row>
          
        </TabPane>

        </TabContent>
          {/* <Row className="mb-2">
            <Col md={2}><h6>{t('email')}:</h6></Col>
            <Col md={4}><p>{choir.email}</p></Col>
            <Col md={2}><h6>{t('telefono')}:</h6></Col>
            <Col md={4}><p>{choir.telefono}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('anni_attivita')}:</h6></Col>
            <Col md={4}><p>{choir.anni_attivita}</p></Col>
            <Col md={2}><h6>{t('numero_cantori')}:</h6></Col>
            <Col md={4}><p>{choir.numero_cantori}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('indirizzo')}:</h6></Col>
            <Col md={10}><p>{choir.indirizzo}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('tipologia_voci')}:</h6></Col>
            <Col md={4}><p>{choirData.tipologia_voci}</p></Col>
            <Col md={2}><h6>{t('tipologia_coro')}:</h6></Col>
            <Col md={4}><p>{choirData.tipologia_coro}</p></Col>
          </Row> */}
          <hr />
          {/* <Row className="">
            <Col>
              <h6>{t('nostra_storia')}:</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <p className="text-justify"><span dangerouslySetInnerHTML={{ __html: converter.makeHtml(choirData.nostra_storia != undefined ? choirData.nostra_storia : "") }} />
           </p></Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col>
              <h6>{t('trovate_su')}:</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('sito_web')}:</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.sito_web}`}>{choir.sito_web}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Facebook:</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.facebook}`}>{choir.facebook}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Instagram:</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.instagram}`}>{choir.instagram}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Flickr:</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.flickr}`}>{choir.flickr}</a></p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>Youtube:</h6></Col>
            <Col md={10}><p><a target="_blank" href={`${choir.youtube}`}>{choir.youtube}</a></p></Col>
          </Row>
          <hr />
          <Row className="">
            <Col>
              <h6>{t('descrizione')}:</h6>
            </Col>
          </Row>
          {choirData.description ? (
            <Col>
              <Row className="mb-2">
                <Col>
                  <h6>{t('3_aggettivi')}:</h6>
                  <p className="text-justify">{choirData.description.aggettivi}</p><br/>
                  <p className="text-justify">{choirData.description.commento}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <h6>{t('cantori')}:</h6>
                  <p className="text-justify">{choirData.description.cantori}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <h6>{t('valori_ideali')}:</h6>
                  <p className="text-justify">{choirData.description.valori_ideali}</p>
                </Col>
              </Row>
            </Col>
          ) : (<></>)} */}
          {/* <hr />
          <Row className="mb-2">
            <Col md={2}><h6>{t('tipo_scambio')}:</h6></Col>
            <Col md={4}><p>{choirData.tipo_scambio && choirData.tipo_scambio.map((s: any) => (<span>{s.tipologia_scambio} /</span>))}</p></Col>
            <Col md={2}><h6>{t('periodo_ospitato')}:</h6></Col>
            <Col md={4}>{choirData.date_ospitato && choirData.date_ospitato.map((p: any) => (<p>{p.mese} {p.anno}</p>))}</Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('mete_preferite')}:</h6></Col>
            <Col md={4}><p>{choirData.destinazioni && choirData.destinazioni.map((d: any) => (<span> {d.destinazioni_preferite} /</span>))}</p></Col>
            <Col md={2}><h6>{t('periodo_ospitare')}:</h6></Col>
            <Col md={4}>{choirData.date_ospitare && choirData.date_ospitare.map((p: any) => (<p>{p.mese} {p.anno}</p>))}</Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col>
              <h6>{t('proposta_ospitalita')}:</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('viaggio')}:</h6></Col>
            <Col>
              <p className="text-justify">{choirData.viaggio}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('alloggio')}:</h6></Col>
            <Col><p>{choirData.alloggio && choirData.alloggio.map((a: any) => (<span>{a.alloggio} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('vitto')}:</h6></Col>
            <Col><p>{choirData.vitto && choirData.vitto.map((v: any) => (<span>{v.vitto} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('trasporto')}:</h6></Col>
            <Col><p>{choirData.trasporto && choirData.trasporto.map((t: any) => (<span>{t.trasporto} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('concerti')}:</h6></Col>
            <Col md={4}><p>{choirData.numero_concerti}</p></Col>
            <Col md={2}><h6>{t('l_concerti')}:</h6></Col>
            <Col md={4}><p>{choirData.luoghi_concerti && choirData.luoghi_concerti.map((l: any) => (<span>{l.luoghi_concerti} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('attrezzature')}:</h6></Col>
            <Col><p>{choirData.attrezzatura && choirData.attrezzatura.map((a: any) => (<span>{a.attrezzatura} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('servizi_agg')}:</h6></Col>
            <Col><p>{choirData.servizi_aggiuntivi && choirData.servizi_aggiuntivi.map((s: any) => (<span>{s.servizi_aggiuntivi} / </span>))}</p></Col>
          </Row>
          <Row className="mb-2">
            <Col md={2}><h6>{t('lingue_parlate')}:</h6></Col>
            <Col><p>{choirData.lingue_parlate && choirData.lingue_parlate.map((l: any) => (<span>{l.lingue_parlate} / </span>))}</p></Col>
          </Row>
          <hr />
          <Row className="">
            <Col>
              <h6>{t('proposta_scambio')}:</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <p className="text-justify">{choirData.proposta_scambio}</p>
            </Col>
          </Row> */}
        </Container>
      </CardBody>
    </Card>

  )
}