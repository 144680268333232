import React, { useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Container,
  InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  TabPane,
  TabContent,
  CardFooter,
  Badge
} from 'reactstrap';
import { Button as ButtonReact } from 'reactstrap'
import classnames from 'classnames';
import 'react-chat-elements/dist/main.css';
// MessageBox component
import { MessageBox, ChatItem, MessageList, ChatList, Input, Button } from 'react-chat-elements';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useHistory } from 'react-router-dom';
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import emailjs from 'emailjs-com';
import { uri, url_img } from '../../backend';
import { useEffect } from 'react';
import fotocoro1 from '../../assets/img/coro.jpg'
import fotocoro2 from '../../assets/img/vokalensemble.jpg'
import fotocoro3 from '../../assets/img/coro_pacini.jpg'
const json = require('../../model.json');
if (navigator.language !== 'it-IT') {
  json.locale = "en"
}
const model = new Survey.Model(json);
const onSurveyComplete = async (survey: any, options: any) => {

  console.log('received data: ', survey.valuesHash);
  const result = await emailjs.send('service_choirex_dev', 'template_5tdtjr8', { message: JSON.stringify(survey.valuesHash) }, "user_xEBz3J4puqVSrggiPM0cx")
  console.log('with result: ', result);

}
const client = new ApolloClient({
  uri: uri,
  cache: new InMemoryCache()
});
export const Messaggi = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [feedback, setFeedback] = React.useState(false)
  const [choirs, setChoirs] = React.useState([] as any)
  var myCss = {
    matrix: { root: "table table-striped matrixtable" },
  };
  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t, i18n } = useTranslation('messaggi', { useSuspense: false });
  useEffect(() => {
    console.log('in start effect')

    const logged = localStorage.getItem('logged');
    const _ = (
      async () => {
        const result = await client.query({
          query: gql`
              query test{
                choirs{
                  id
                  nome
                  indirizzo
                  latitudine
                  longitudine
                  sito_web
                  anni_attivita
                  email
                  telefono
                  images{
                    name
                    url
                  }
                }
              }
          `
        })
        console.log('after result: ', result)
        setChoirs(result.data.choirs);
        console.log('after result:1 ', choirs)

      }
    )()
    return () => { }

  }, [])

  const chatList=[[
    {
      position: 'right',
      avatar: `${fotocoro2}`,
      title: <h6 >Vokalensemble Chorioso</h6>,
      type: 'text',
      text: <p >Hello we have seen the festival that you organize every year and we would like to offer you our program of German and Italian music (we attach the details in the email). Our group is 16 people and we are available to host you in turn, even if we do not have a structured review like yours, organizing concerts. Awaiting your reply, a cordial greeting from Germany!</p>,
      date: new Date(2019, 10, 12, 10, 33),
    }, {
      position: 'left',
      avatar: 'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg',
      title: <h6 >Ad libitum</h6>,
      type: 'text',
      text: <p>Hello, thank you very much for the proposal, yes, we can certainly talk about it, we already have some festival dates but the programming is not complete. Can we talk to your artistic director by phone?</p>,
      date: new Date(2019, 10, 19, 10, 33),
    }
  ],[
    {
      position: 'right',
      avatar: `${fotocoro1}`,
      title: <h6 >Coro de armonía</h6>,
      type: 'text',
      text: <p >Ciao, desideriamo da molto tempo visitare la Sardegna! Sareste disponibili ad ospitarci nel 2021? Stiamo programmando il nostro anno corale e potremmo muoverci nei mesi estivi. La nostra proposta è per uno scambio non reciproco: pagheremmo noi le spese di viaggio vitto e alloggio e a voi chiediamo se potete organizzarci dei concerti. Dal nostro profilo potete ascoltare un po’ della nostra musica, poi, se interessati, il nostro direttore può parlarvi meglio del nostro repertorio e dei nostri progetti. Grazie per l’attenzione, a presto 😊</p>,
      date: new Date(2020, 7, 18, 10, 33),
    }, {
      position: 'left',
      avatar: 'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg',
      title: <h6 >Ad libitum</h6>,
      type: 'text',
      text: <p>Ciao, grazie mille per la richiesta, per il 2021 però abbiamo già fatto dei programmi e non abbiamo la possibilità di dedicare del tempo al vostro progetto. Potremmo però ragionare su una ospitalità per la prima metà del 2022, che ne dite?</p>,
      date: new Date(2020, 7, 29, 10, 33),
    }
  ],[
    {
      position: 'right',
      avatar: `${fotocoro3}`,
      title: <h6 >Coro Polifonico A. Pacini</h6>,
      type: 'text',
      text: <p >Ciao, ci piacerebbe invitarvi a partecipare come ospiti alla nostra Rassegna Polifonica Internazionale (ventiseiesima edizione quest’anno) che organizziamo nei giorni della festa di Santa Cecilia e della musica. Due concerti in cui cantare insieme con noi, poi possiamo aiutarvi a cercare altre date nel territorio. Abbiamo specificato tutto nella nostra proposta di scambio, pensate di essere disponibili?</p>,
      date: new Date(2019, 2, 3, 10, 33),
    }, {
      position: 'left',
      avatar: 'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg',
      title: <h6 >Ad libitum</h6>,
      type: 'text',
      text: <p>Ciao! Ci piacerebbe molto tornare ad Atri, in Abruzzo ci siamo sempre trovati benissimo! Da capire se riusciamo ad organizzare la trasferta per novembre, che è un periodo un po’ particolare. Parliamo con i cantori e ci risentiamo tra qualche giorno per capire le adesioni. A presto!</p>,
      date: new Date(2019, 2, 4, 10, 33),
    }
  ]]
  const [chat, setChat]= React.useState(chatList[0])
  const [activeCard, setActiveCard]= React.useState(0)

  const [isTourOpen, setIsTourOpen] = useState(false);
  const steps = [
    {
      selector: '.message-list',
      content: <p>{t("step1")}</p>
      // content: <p>I dettagli sul <span className="text-success"><strong>vostro incontro</strong></span></p>
    },
    {
      selector: '.message-list',
      content: <div><p>{t("step2")}</p>
        <ButtonReact color="success" onClick={() => {
          setFeedback(true)
          setIsTourOpen(false)
        }}>{t('feedback')}</ButtonReact>
      </div>
      // content: <p>I dettagli sul <span className="text-success"><strong>vostro incontro</strong></span></p>
    }
  ]
  const history = useHistory();
  const toggleModal = () => setFeedback(!feedback);

  const disableBody = (target: any) => disableBodyScroll(target)
  const enableBody = (target: any) => enableBodyScroll(target)
  return (
    <>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        accentColor="#00A0A4"
        maskSpace={32}
        rounded={32}
      />
      <Header />
      <Container fluid style={{ backgroundColor: '#CCCCCC', paddingTop: 10, minHeight: '100vh' }}>
        <Row>
          <Col xs='12' md='3'>
            <Card className="no-transition mt-4" style={{ minHeight: '60vh' }}>
              <CardHeader className="text-center"><h5>{t('chatlist')}</h5></CardHeader>
              <ChatList
                className='chat-list'
                dataSource={[
                  {
                    avatar: `${fotocoro2}`,
                    alt: 'Vokalensemble Chorioso',
                    title: <ButtonReact onClick={() => {setChat(chatList[0]); setActiveCard(0)}}>Vokalensemble Chorioso</ButtonReact>,
                    subtitle: <Badge>SCAMBIO IN DISCUSSIONE</Badge>,
                    date: new Date(2019, 10, 19, 10, 33),
                    unread: 0,
                  }, {
                    avatar: `${fotocoro1}`,
                    alt: 'Coro de Armonía',
                    title: <ButtonReact onClick={() => {setChat(chatList[1]); setActiveCard(1)}}>Coro de Armonía</ButtonReact>,
                    subtitle: <Badge>SCAMBIO DA AGGIORNARE</Badge>,
                    date: new Date(2020, 1, 19, 10, 33),
                    unread: 0,
                  }, {
                    avatar: `${fotocoro3}`,
                    alt: 'Coro de Armonía',
                    title: <ButtonReact onClick={() => {setChat(chatList[2]); setActiveCard(2)}}>Coro Polifonico A. Pacini</ButtonReact>,
                    subtitle: <Badge>SCAMBIO COMPLETATO</Badge>,
                    date: new Date(2019, 2, 4, 10, 33),
                    unread: 0,
                  }
                ]} />
            </Card>
          </Col>
          <Col xs='12' md='6' className="d-sm-inline-block align-middle justify-content-center align-items-center " style={{ minHeight: '100vh' }}>
            <Card className="no-transition mt-4" style={{ minHeight: '60vh' }}>
              <CardHeader className="text-center"><h5>{t('chat')} {activeCard==0 ? (' Vokalensemble Chorioso'): ( activeCard==1 ? (' Coro de Armonía'):('Coro Polifonico A. Pacini'))}</h5></CardHeader>
              <MessageList
                className='message-list'
                lockable={true}
                toBottomHeight={'100%'}
                dataSource={chat}/>
              <CardFooter>
                <Input
                  placeholder={t('scrivi_messaggio')}
                  multiline={true}
                  rightButtons={
                    <Button
                      color='white'
                      backgroundColor='green'
                      text={t('invia')} />
                  } />
              </CardFooter>
            </Card>
          </Col>
          <Col xs='12' md='3'  >
            {activeCard==0 ? (
              <Card className="no-transition mt-4" style={{ minHeight: '60vh' }}>
                <CardHeader className="text-center">
                  <h5>{t('tuoscambio')}Vokalensemble Chorioso</h5>
                </CardHeader>
                <CardBody>
                    <CardImg style={{ borderRadius: 3, width: "100%" }} top src={fotocoro2} alt="Card image cap" />
                  <CardText className="mt-4 text-center">
                    <h5 className="mb-2">{t('camere_ospiti2')}</h5>
                    <h5 className="mb-2">{t('scambio2')}</h5>
                    <h5>{t('periodo2')}</h5>
                  </CardText>
                </CardBody>
                <CardFooter className="text-center">
                  <ButtonReact color='success' outline><a><h6>{t('profilo')}</h6></a></ButtonReact>
                </CardFooter>
              </Card>
            ) : ( activeCard==1 ? (
              <Card className="no-transition mt-4" style={{ minHeight: '60vh' }}>
              <CardHeader className="text-center">
                <h5>{t('tuoscambio')} Coro de Armonía</h5>
              </CardHeader>
              <CardBody>
                  <CardImg style={{ borderRadius: 3, width: "100%" }} top src={fotocoro1} alt="Card image cap" />
                <CardText className="mt-4 text-center">
                  <h5 className="mb-2">{t('camere_ospiti')}</h5>
                  <h5 className="mb-2">{t('scambio')}</h5>
                  <h5>{t('periodo')}</h5>
                </CardText>
              </CardBody>
              <CardFooter className="text-center">
                <ButtonReact color='success' outline><a href="http://www.choirex.com/coro/16"><h6 >{t('profilo')}</h6></a></ButtonReact>
              </CardFooter>
            </Card>
            ):(
              <Card className="no-transition mt-4" style={{ minHeight: '60vh' }}>
              <CardHeader className="text-center">
                <h5>{t('tuoscambio')} Coro Polifonico A. Pacini</h5>
              </CardHeader>
              <CardBody>
                  <CardImg style={{ borderRadius: 3, width: "100%" }} top src={fotocoro3} alt="Card image cap" />
                <CardText className="mt-4 text-center">
                  <h5 className="mb-2">{t('camere_ospiti')}</h5>
                  <h5 className="mb-2">{t('scambio')}</h5>
                  <h5>Atri (IT)</h5>
                </CardText>
              </CardBody>
              <CardFooter className="text-center">
                {/* <ButtonReact color='success' outline><a href="http://www.choirex.com/coro/16"><h6 >{t('profilo')}</h6></a></ButtonReact> */}
              </CardFooter>
            </Card>
            ))}
          </Col>
        </Row>
        <div className="shadow-lg" style={{ position: 'fixed', borderRadius: 32, bottom: 10, right: 10, zIndex: 10000, backgroundColor: 'transparent' }}>
          <ButtonReact size="lg" className="shadow-lg btn-round mb-0 mt-0 " style={{ height: 60 }} color="success" onClick={() => {
            setIsTourOpen(true);
          }}>{t("continua")}</ButtonReact>
        </div>
      </Container>

      <Modal fluid toggle={toggleModal} isOpen={feedback} style={{ paddingBottom: '30px' }}>
        <Card className="no-transition pl-0 pr-0 bl-0 bb-0 pb-0 mb-20 mt-0">
          <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="bg-primary text-center"><span className="text-white text-uppercase">{t('raccontaci')}</span></CardHeader>
          <CardBody >
            <Survey.Survey style={{ textAlign: 'left' }} model={model} css={myCss} onComplete={onSurveyComplete} />
          </CardBody>
        </Card>
      </Modal>
    </>
  )
}