import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  TabContent,
  TabPane,
  Table,
  CardFooter
} from 'reactstrap';
import classnames from 'classnames';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';


export const MioAbbonamento = () => {

  const [activeTab, setActiveTab] = useState('1');
  const { t, i18n } = useTranslation('abbonamento', { useSuspense: false });

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  return (
    <>
      <Header />
      <Container fluid style={{ minHeight: '100vh' }}>
        <Card className="mt-4">
          <CardHeader><h6>{t('ilmioabbonamento')}</h6></CardHeader>
          <Row >
            <Col xs='12' md='3'>
              <Nav vertical tabs style={{cursor:"pointer"}}>
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}>{t('riepilogo')}</NavLink>
                </NavItem>
                <DropdownItem divider />
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('2'); }}>{t('tua_carta')}</NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs='12' md='9'>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col xs='12' md="12">
                      <Table striped>
                        <thead>
                          <tr>
                            <th>{t('nome_cognome')}</th>
                            <th>{t('abbonamento')}</th>
                            <th>{t('data_acquisto')}</th>
                            <th>{t('rinnovo')}</th>
                            <th>{t('stato_pagamento')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Luigi Conti</th>
                            <td>Annuale</td>
                            <td>20-01-2020</td>
                            <td>20-01-2021</td>
                            <td>Attivo</td>
                          </tr>
                          <tr>
                            <th scope="row">Franco Rossi</th>
                            <td>Annuale</td>
                            <td>30-06-2020</td>
                            <td>30-06-2021</td>
                            <td>Attivo</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md="3"></Col>
                    <Col xs='12' md="6">
                      <Card body>
                        <CardTitle>4573 3845 3421 5644</CardTitle>
                        <CardText>{t('titolare')}: Luigi Conti</CardText>
                        <CardFooter>
                          <Button>{t('modifica')}</Button>
                          <Button>{t('elimina')}</Button>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col sm="3"></Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Card>

      </Container></>
  )
}