import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  TabPane,
  TabContent
} from 'reactstrap';
import classnames from 'classnames';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';


export const Aiuto = () => {

  const [activeTab, setActiveTab] = useState('1');
  const { t, i18n } = useTranslation('aiuto', { useSuspense: false });

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  return (
    <>
      <Header />
      <Container fluid style={{ minHeight: '100vh' }}>
        <Row>
          <Col style={{ minHeight: '100vh' }} xs='4'>
            <Nav vertical>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}>{t('dom_1')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}>{t('dom_2')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}>{t('dom_3')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggle('4'); }}>{t('dom_4')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggle('5'); }}>{t('dom_5')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '6' })}
                  onClick={() => { toggle('6'); }}>{t('dom_6')}</NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs='8' className="d-sm-inline-block align-middle  justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">{t('risp_1')}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    {t('risp_2')} </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    {t('risp_3')}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    {t('risp_4')}</Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                    {t('risp_5')}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">{t('risp_6')}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

      </Container></>
  )
}