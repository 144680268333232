import React, { useEffect } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  CardImgOverlay,
  CardFooter,
  Badge
} from 'reactstrap';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';
import ReactStars from "react-rating-stars-component";

export const IMieiScambi = () => {
  const { t, i18n } = useTranslation('mieiscambi', { useSuspense: false });


  return (
    <>
      <Header />
      <Container fluid style={{ minHeight: '100vh' }}>
        <Row>
          <Col>
            <Card className="mt-4">
              <CardHeader><h6>{t('mie_recensioni')}</h6></CardHeader>
              <CardTitle>{t('soddisfatto')}</CardTitle>
              <Row>
                <Col xs='12' md="4">
                  <Input type="select" name="select" id="exampleSelect" >
                    <option ></option>
                    <option>Coro</option>
                    <option></option>
                    <option></option>
                  </Input>
                </Col>
                <Col xs='12' md="4">
                  <Input type="date" name="date" id="exampleSelect" ></Input>
                </Col>
                <Col xs='12' md="4">
                  <Button>{t('add_recensione')}</Button>
                </Col>
              </Row>
              <CardBody>
                <Col>
                  <Row>
                    <Card>
                      <CardHeader><h6>Atri - Coro Schola Cantorum A. Pacini- novembre 2019</h6></CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs='12' md='2'>
                            <CardImg style={{ borderRadius: 0 }} top width="300" height="150" src={'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg'} alt="immagine Ad Libitum" />
                          </Col>
                          <Col xs='12' md='8'>
                            <CardText>
                              In un bellissimo borgo del sud Italia siamo stati accolti da un gruppo che da oltre trenta anni canta insieme: un club corale al maschile! Due concerti in due delle chiese più belle del territorio, in cui i nostri due cori hanno cantato un po’ per uno, un pranzo luculliano nella loro bella sede sociale, cucinato interamente da loro (niente mogli!), per farci scoprire le specialità del luogo. Le giornate sono state equamente divise tra musica e scoperta del patrimonio artistico della città e hanno consolidato una bella amicizia corale che sicuramente durerà nel tempo
                         </CardText>
                          </Col>
                          <Col xs='12' md='2'>
                            <Row>
                              <ReactStars
                                edit={false}
                                value={4}
                                size={44}
                                activeColor="#ffd700"
                              /></Row>
                            <Row><Badge>{t('badge1')}</Badge></Row>
                            <Row>
                              <Button>{t('modifica')}</Button>
                              <Button disabled>{t('salva')}</Button>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Card>
                      <CardHeader><h6>Dorgali – Coro Polifonico Perosi – luglio 2019</h6></CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs='12' md='2'>
                            <CardImg style={{ borderRadius: 0 }} top width="300" height="150" src={'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg'} alt="immagine Ad Libitum" />
                          </Col>
                          <Col xs='12' md='8'>
                            <CardText>
                              Quando stanchi dopo il lungo viaggio, fiaccati dal caldo ancora forte, si è aperto il cancello di ferro delle grotte di Ispinigoli, la meraviglia ci ha travolto! Si apre davanti a noi una voragine, con una colonna calcarea centrale ben illuminata, infinite varietà di concrezioni, aperture di spazi ora ampi ora profondi e ristretti. Il coro ospite ci attende giù, al fondo dei 400 gradini che scendiamo in fila indiana, silenziosamente, per lo stupore ed il rispetto di quello che ci circonda. Il pubblico si è distribuito nei vari spazi a gruppetti. Non avevamo mai cantato in una grotta, temevamo che la voce si sarebbe persa nei meandri di quella voragine ma le voci salgono ben amalgamate e quando arrivano gli applausi dall’altro, dai lati, da vicino e da lontano, l’emozione è forte. La bellezza degli scambi è sempre il confronto fra diverse sensibilità canore, ma qui dobbiamo ammettere che la grotta ha aggiunto meravigliose sensazioni visive e sonore. Un luogo indimenticabile! Indimenticabile del resto anche la generosa cena barbaricina del dopo concerto, il momento in cui cantare a squarciagola è d’obbligo!
                         </CardText>
                          </Col>
                          <Col xs='12' md='2'>
                            <Row>
                              <ReactStars
                                edit={false}
                                value={5}
                                size={44}
                                activeColor="#ffd700"
                              /></Row>
                            <Row><Badge>{t('badge2')}</Badge></Row>
                            <Row>
                              <Button>{t('modifica')}</Button>
                              <Button disabled>{t('salva')}</Button>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>

                  <Row>
                    <Card>
                      <CardHeader><h6>Ferrara – Coro Coralmente – ottobre 2018</h6></CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs='12' md='2'>
                            <CardImg style={{ borderRadius: 0 }} top width="300" height="150" src={'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg'} alt="immagine Ad Libitum" />
                          </Col>
                          <Col xs='12' md='8'>
                            <CardText>
                              Quello che ci ha colpito di questa esperienza è stato incontrarci con un gruppo che, anche se molto lontano geograficamente, condivide il nostro stesso spirito. Ci siamo ritrovati per lunghe sessioni di prova, per il piacere dello studio, abbiamo cucinato e condiviso i pasti e abbiamo poi realizzato due concerti in due sedi storiche piene di suggestione, seguiti da un pubblico molto numeroso e attento. La sistemazione nelle case dei cantori ci ha permesso di conoscerci di più e condividere le nostre esperienze: da ripetere! Ottima anche l'organizzazione del coro nel gestire gli spostamenti dalle case private ai luoghi delle attività collettive: per noi è stato comodissimo!
                            </CardText>
                          </Col>
                          <Col xs='12' md='2'>
                            <Row>
                              <ReactStars
                                edit={false}
                                value={5}
                                size={44}
                                activeColor="#ffd700"
                              /></Row>
                            <Row><Badge>{t('badge3')}</Badge></Row>
                            <Row>
                              <Button>{t('modifica')}</Button>
                              <Button disabled>{t('salva')}</Button>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Card>
                      <CardHeader><h6> Hannover – Coro Norddeutscher Figuralchor – giugno 2018</h6></CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs='12' md='2'>
                            <CardImg style={{ borderRadius: 0 }} top width="300" height="150" src={'https://choirex-backend-heroku.herokuapp.com/uploads/3_40e5a2324e.jpg'} alt="immagine Ad Libitum" />
                          </Col>
                          <Col xs='12' md='8'>
                            <CardText>
                              Una cura per i dettagli che ci ha fatto sentire dei principi: gli ospiti ci hanno chiesto informazioni rispetto ai nostri gusti alimentari, alle intolleranze, alle preferenze di intrattenimento (visite guidate, passeggiate all’aria aperta, shopping) fornendoci le migliori soluzioni, a volte personalizzate! Orari delle prove e di tutto il programma stabiliti con largo anticipo e rispettati con precisione, assistenza durante le prove per ogni esigenza, ma soprattutto presenza di pubblico ai concerti altissima! In Italia sarebbe difficile ma qui tutti sembrano appassionati di coro, che bello!
                         </CardText>
                          </Col>
                          <Col xs='12' md='2'>
                            <Row>
                              <ReactStars
                                edit={false}
                                value={5}
                                size={44}
                                activeColor="#ffd700"
                              /></Row>
                            <Row><Badge>{t('badge4')}</Badge></Row>
                            <Row>
                              <Button>{t('modifica')}</Button>
                              <Button disabled>{t('salva')}</Button>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container></>
  )
}