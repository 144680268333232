import React, { useEffect, useState } from 'react';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal, Tooltip, Popover, PopoverHeader, ModalHeader, ModalBody, ModalFooter, InputGroupText
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import emailjs from 'emailjs-com';
const json = require('../model.json');
if (navigator.language !== 'it-IT') {
  json.locale = "en"
}
const model = new Survey.Model(json);

export const Header = () => {
  const { t, i18n } = useTranslation('header', { useSuspense: false });
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);
  const [feedback, setFeedback] = React.useState(false)
  const toggleModal = () => setFeedback(!feedback);
  const [lngChanged, setLngChanged] = useState(false);

  console.log("header", window.location.pathname)
  const toggle = () => setIsOpen(!isOpen);
  const toggle1 = () => setIsOpen1(!isOpen1);

  let path = "/"
  if (window.location.pathname !== "/platform") {
    path = "/platform"
  }
  var myCss = {
    matrix: { root: "table table-striped matrixtable" },
  };
  const onSurveyComplete = async (survey: any, options: any) => {
    console.log('received data: ', survey.valuesHash);
    const result = await emailjs.send('service_choirex_dev', 'template_5tdtjr8', { message: JSON.stringify(survey.valuesHash) }, "user_xEBz3J4puqVSrggiPM0cx")
  }

  return (
    <Navbar color="success" expand="xs">
      <NavbarBrand href={path}><img style={{ height: 35 }} src="/assets/ChoirexLogoWhite.png" /></NavbarBrand>
      <NavbarToggler onClick={toggle} className="ml-auto" />
      <Collapse isOpen={!isOpen} navbar >
        <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar >
            <DropdownToggle nav caret>
              <i className="fas fa-globe"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => { localStorage.setItem('language', "it"); i18n.changeLanguage('it') }}>
                <i className="fas fa-globe"></i> Italiano (IT)              </DropdownItem>
              <DropdownItem onClick={() => { localStorage.setItem('language', "en"); i18n.changeLanguage('en') }}>
                <i className="fas fa-globe"></i> English (EN)              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar >
            <DropdownToggle nav caret>
              {t('ilmioprofilo')}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem href="/profilo">
                {t('imieidati')}
              </DropdownItem>
              <DropdownItem href="/coro/16">
                {t('ilmiocoro')}
              </DropdownItem>
              <DropdownItem href="/miocoro">
                {t('aggiungi')}
              </DropdownItem>
              <DropdownItem href="/imieiscambi">
                {t('imieiscambi')}
              </DropdownItem>
              <DropdownItem href="/abbonamento">
                {t('ilmioabbonamento')}
              </DropdownItem>
              <DropdownItem href="/messaggi">
                {t('messaggi')}
              </DropdownItem>
              <DropdownItem onClick={() => setFeedback(true)}>
                {t('feedback')}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="/" onClick={() => localStorage.removeItem('logged')
              }>
                {t('esci')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
      <Modal fluid toggle={toggleModal} isOpen={feedback} style={{ paddingBottom: '30px' }}>
        <Card className="no-transition pl-0 pr-0 bl-0 bb-0 pb-0 mb-20 mt-0">
          <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="bg-primary text-center"><span className="text-white text-uppercase">{t('raccontaci')}</span></CardHeader>
          <CardBody >
            <Survey.Survey style={{ textAlign: 'left' }} model={model} css={myCss} onComplete={onSurveyComplete} />
          </CardBody>
        </Card>
      </Modal>
    </Navbar>

  )
}
