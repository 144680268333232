import React from 'react';
import logo from './logo.svg';
// import './App.css';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import { uri, url_img } from './../backend';
import { useTranslation, Trans } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { url } from 'inspector';
import { DivIcon } from 'leaflet';
import { Card, CardBody, CardHeader, Modal } from 'reactstrap';
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [
  <div className="item">1</div>,
  <div className="item">2</div>,
  <div className="item">3</div>,
  <div className="item">4</div>,
  <div className="item">5</div>,
];

const Carousel = () => (
  <AliceCarousel
    mouseTracking
    items={items}
    responsive={responsive}
  />
);
let lan = localStorage.getItem('language');
var json = require('../model.json');
var jsonPrice = require('../model-landing.json');
if(lan){
  json.locale = lan
  jsonPrice.locale = lan
}else{
  if (navigator.language !== 'it-IT') {
  json.locale = "en"
  jsonPrice.locale = "en"
}
}
let model = new Survey.Model(json);
let modelPrice = new Survey.Model(jsonPrice);

const onSurveyComplete = async (survey: any, options: any) => {
  console.log('received data: ', survey.valuesHash);
  const result = await emailjs.send('service_choirex_dev', 'template_5tdtjr8', { message: JSON.stringify(survey.valuesHash) }, "user_xEBz3J4puqVSrggiPM0cx")
  console.log('with result: ', result);
}
var showdown = require('showdown')
var myext = {
  type: 'output',
  filter: function (text: any, converter: any, options: any) {
    console.log('in filter with: ', text)
    return text.replace(/<li>/g, '<li><i className="bx bx-check-double"></i>');
  }
};
showdown.extension('myext', myext);
const converter = new showdown.Converter({ extensions: [myext] });
const client = new ApolloClient({
  uri: uri,
  cache: new InMemoryCache()
});


export const LandingPage = () => {
  let lan = "ita"
  var lng: any = (window as any).navigator['userLanguage'] || (window as any).navigator.language;
  if (lng !== 'it-IT') {
    lan = "eng"
  } else {
    lan = "ita"
  }
  const [mainSection, setMainSection] = React.useState({} as any)
  const [homeLangData, setHomeLangData] = React.useState({} as any)
  const [homeLink, setHomeLink] = React.useState("")
  const [feedback, setFeedback] = React.useState(false)
  const [feedback1, setFeedback1] = React.useState(false)
  const toggle = () => setFeedback(!feedback);
  const toggle1 = () => setFeedback1(!feedback1);
  var myCss = {
    matrix: { root: "table table-striped matrixtable" },
  };
  const { t, i18n } = useTranslation('landing', { useSuspense: false });
  React.useEffect(() => {
    console.log('in start effect')
    const _ = (
      async () => {
        const newHome = await client.query({
          query: gql`
              query test1{
                homes{
                  id
                  link
                  localized{
                    language
                    titolo
                    descrizione
                    section1
                    section2
                    section3
                    come_funziona
                    titolo2
                    nav1
                    nav2
                    nav3
                    nav4
                    tabtitolo1
                    tabtitolo2
                    tabtitolo3
                    tabtitolo4
                    tab110
                    tab210
                    tab310
                    tab410
                    titolo3prezzo
                    prezzo1
                    prezzo2
                    prezzo3
                    prezzo4
                    prezzo5
                    prezzo6
                    priceCentral
                    buttonPrezzo
                  }
                }
              }
          `
        })
        console.log('after result: ', newHome, navigator.language)
        // setMainSection(result.data.homepages[0]);
        let mainsection = newHome.data.homes[0]
        setMainSection(newHome.data.homes[0]);
        let homeLang
        let lan = localStorage.getItem('language');
        if (mainsection && mainsection.localized) {
          if (mainsection.localized.length > 1) {
            if(lan){
              if (lan == 'it') {
                console.log("in if 1 ita")
                homeLang = mainsection.localized.find((el: any) =>
                  el.language == 'ita')
              } else {
                homeLang = mainsection.localized.find((el: any) =>
                  el.language == 'eng')
                  console.log("in if 1 eng")
              }
            }else{
            if (navigator.language == 'it-IT') {
              homeLang = mainsection.localized.find((el: any) =>
                el.language == 'ita')
                console.log("in if 2 ita", )
            } else {
              homeLang = mainsection.localized.find((el: any) =>
                el.language == 'eng')
                console.log("in if 2 eng")
            }}
          } else {
            homeLang = mainsection.localized[0]
          }
        }
        console.log("after homelang data: ", homeLangData, homeLang, mainsection.link)
        setHomeLangData(homeLang)
        setHomeLink(mainsection.link)
      }
    )()
    return () => {
    }
  }, [])
  const changeLanguage = (lng: string) => {
    console.log('fff', lng)

    let homeLang
    if (mainSection && mainSection.localized) {
      if (mainSection.localized.length > 1) {
        homeLang = mainSection.localized.find((el: any) =>
          el.language == lng)
      } else {
        homeLang = mainSection.localized[0]
      }
    }
    let languagei18 = ""
    languagei18 = lng.slice(0, 2);
    console.log("fffff", languagei18, lng)
    localStorage.setItem('language', languagei18)
    i18n.changeLanguage(languagei18);
    jsonPrice.locale = languagei18
    json.locale = languagei18
    model = new Survey.Model(json);
    modelPrice = new Survey.Model(jsonPrice);
    setHomeLangData(homeLang)
    setHomeLink(mainSection.link)
  }

  const aboutRef = React.useRef<null | HTMLDivElement>(null);
  const howItWorksRef = React.useRef<null | HTMLDivElement>(null);
  const howMuchItCostRef = React.useRef<null | HTMLDivElement>(null);
  const contactsRef = React.useRef<null | HTMLDivElement>(null);
  const location = useLocation();

  React.useEffect(() => {
    console.log('in location: ', location)
    if (aboutRef && location.hash.includes('#about')) {

      aboutRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });

    }
  }, [aboutRef, location.hash]);

  React.useEffect(() => {
    console.log('in location: ', location)
    if (howItWorksRef && location.hash.includes('#howItWorks')) {

      howItWorksRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });

    }
  }, [howItWorksRef, location.hash]);

  React.useEffect(() => {
    console.log('in location: ', location)
    if (howMuchItCostRef && location.hash.includes('#howMuchItCost')) {

      howMuchItCostRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });

    }
  }, [howMuchItCostRef, location.hash]);

  React.useEffect(() => {
    console.log('in location: ', location)
    if (contactsRef && location.hash.includes('#contacts')) {

      contactsRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      });

    }
  }, [contactsRef, location.hash]);
  const items = [
    <img width={600} height={360} style={{ paddingRight: 16 }} src={'assets/img/carousel/1.jpg'} alt={`slaaa`} />,
    <img width={600} height={360} style={{ paddingRight: 16 }} src={`assets/img/carousel/2.jpg`} alt={`slaaa`} />,
    <img width={600} height={360} style={{ paddingRight: 16 }} src={`assets/img/carousel/4.jpg`} alt={`slaaa`} />,
    <img width={600} height={360} style={{ paddingRight: 16 }} src={`assets/img/carousel/5.jpg`} alt={`slaaa`} />
  ]

  return (
    <div className="App">

      <header id="header" className="fixed-top">
        <div className="container-fluid d-flex align-items-center justify-content-between">

          <h1 className="logo"><a href="index.html"><img src="/assets/logoVerde.png" /></a></h1>

          <nav className="nav-menu d-none d-lg-block">
            <ul className="justify-content-center">
              <li className="nav-item"><a href="#about">{t('cosaechoirex')}</a></li>
              <li className="nav-item"><a href="#howItWorks">{t('comefunziona')}</a></li>
              <li className="nav-item"><a href="#howMuchItCost">{t('quantocosta')}</a></li>
              <li className="nav-item"><a href="#contacts">{t('contatti')}</a></li>
            </ul>
          </nav>

          <div className="nav-menu d-none d-lg-block">
            <ul>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-globe"></i>
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" onClick={() => { changeLanguage("ita") }}><i className="fas fa-globe"></i> Italiano (IT)</a>
                  <a className="dropdown-item" onClick={() => { changeLanguage("eng") }}><i className="fas fa-globe"></i> English (EN)</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#"></a>
                </div>
              </li>

              <div className="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-align-justify"></i><i className="fas fa-user-circle"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <a className="dropdown-item" href="/platform">{t('accedi')}</a>

                </div>
              </div>
            </ul>
          </div>

        </div>
      </header>

      <section id="hero">
        <video autoPlay muted loop id="myVideo">
          <source src="iStock-903088120.mp4" type="video/mp4" />
        </video>
        <div className="hero-container" data-aos="fade-up" data-aos-delay="150">
          <h1>{t('connettiticonicori')}</h1>
          <h2>{t('dituttoilmondo')}</h2>
          <div className="d-flex">
            <button type="button" className="btn-get-started" data-toggle="modal" data-target="#exampleModal">
              {t('cercauncoro')}
            </button>
          </div>
        </div>
      </section>

      <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{t('benvenuto')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {t('questaedemo')}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" ><a href="/platform">{t('avvia')}</a></button>
            </div>
          </div>
        </div>
      </div>

      <main id="main">



        <section ref={aboutRef} className="about">
          <div className="container" data-aos="fade-up">


            <div className="section-title">
              {homeLangData.titolo ? (<h2 className="section-title">{homeLangData.titolo}</h2>) : (<></>)}
              {homeLangData.descrizione ? (<p className="section-title">{homeLangData.descrizione}</p>) : (<></>)}


            </div>

            <div className="no-transition">
              <AliceCarousel animationDuration={2000} autoPlayStrategy={"all"} autoPlay={true} infinite={true} disableButtonsControls={true} autoWidth mouseTracking items={items} />
            </div>


            <div className="row justify-content-end">
              <div className="col-lg-12">
                <div className="row justify-content-end">
                  <div className="row">

                    <div className="col-lg-4 pt-3 pt-lg-0 content">
                      <div className="icon-box">
                        <div className="icon">
                          <i className="fas fa-camera-retro"></i>
                        </div>
                        {homeLangData.section1 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.section1 != undefined ? homeLangData.section1 : "") }} />) : (<></>)}
                      </div>
                    </div>


                    <div className="col-lg-4 pt-3 pt-lg-0 content">
                      <div className="icon-box">
                        <div className="icon">
                          {/* <i className="fal fa-cloud-music"></i>                      */}
                          <img style={{ width: 62 }} src="cloudGreen.svg" className="img-fluid" alt="" />
                        </div>
                        {homeLangData.section2 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.section2 != undefined ? homeLangData.section2 : "") }} />) : (<></>)}
                      </div>
                    </div>

                    <div className="col-lg-4 pt-3 pt-lg-0 content">
                      <div className="icon-box">
                        <div className="icon">
                          <i className="fas fa-suitcase"></i>                       </div>
                        {homeLangData.section3 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.section3 != undefined ? homeLangData.section3 : "") }} />) : (<></>)}
                      </div>
                    </div>


                    <div className="col-lg-12">
                      <video style={{ width: 200, marginTop: 30 }} autoPlay muted loop id="myVideo">
                        <source src="cuffie.mp4" type="video/mp4" />
                      </video>
                      {/* <img src="assets/img/about.jpg" className="img-fluid" alt="" /> */}
                      {/* <a href={`${homeLink}`} className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>


        <section ref={howItWorksRef} className="departments">
          <div className="container" data-aos="fade-up">


            <div className="section-title">
              {homeLangData.titolo2 ? (<h2 >{homeLangData.titolo2}</h2>) : (<></>)}
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item">
                    <a className="nav-link active show" data-toggle="tab" href="#tab-1">
                      {homeLangData.nav1 ? (<h4>{homeLangData.nav1}</h4>) : (<></>)}
                      <p></p>
                    </a>
                  </li>
                  <li className="nav-item mt-2">
                    <a className="nav-link" data-toggle="tab" href="#tab-2">
                      {homeLangData.nav2 ? (<h4>{homeLangData.nav2}</h4>) : (<></>)}
                      <p>
                      </p>
                    </a>
                  </li>
                  <li className="nav-item mt-2">
                    <a className="nav-link" data-toggle="tab" href="#tab-3">
                      {homeLangData.nav3 ? (<h4>{homeLangData.nav3}</h4>) : (<></>)}
                      <p></p>
                    </a>
                  </li>
                  <li className="nav-item mt-2">
                    <a className="nav-link" data-toggle="tab" href="#tab-4">
                      {homeLangData.nav4 ? (<h4>{homeLangData.nav4}</h4>) : (<></>)}
                      <p></p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8">
                <div className="tab-content">
                  <div className="tab-pane active show" id="tab-1">
                    {homeLangData.tabtitolo1 ? (<h3>{homeLangData.tabtitolo1}</h3>) : (<></>)}
                    <p className="font-italic"></p>
                    <img src="assets/img/departments-1.jpg" alt="" className="img-fluid"></img>
                    {homeLangData.tab110 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.tab110 != undefined ? homeLangData.tab110 : "") }} />) : (<></>)}
                  </div>
                  <div className="tab-pane" id="tab-2">
                    {homeLangData.tabtitolo2 ? (<h3>{homeLangData.tabtitolo2}</h3>) : (<></>)}
                    <p className="font-italic"></p>
                    <img src="assets/img/departments-2.jpg" alt="" className="img-fluid"></img>
                    {homeLangData.tab210 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.tab210 != undefined ? homeLangData.tab210 : "") }} />) : (<></>)}
                  </div>
                  <div className="tab-pane" id="tab-3">
                    {homeLangData.tabtitolo3 ? (<h3>{homeLangData.tabtitolo3}</h3>) : (<></>)}
                    <p className="font-italic"></p>
                    <img src="assets/img/departments-3.jpg" alt="" className="img-fluid"></img>
                    {homeLangData.tab310 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.tab310 != undefined ? homeLangData.tab310 : "") }} />) : (<></>)}
                  </div>
                  <div className="tab-pane" id="tab-4">
                    {homeLangData.tabtitolo4 ? (<h3>{homeLangData.tabtitolo4}</h3>) : (<></>)}
                    <p className="font-italic"></p>
                    <img src="assets/img/departments-4.jpg" alt="" className="img-fluid"></img>
                    {homeLangData.tab410 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.tab410 != undefined ? homeLangData.tab410 : "") }} />) : (<></>)}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>




        <section ref={howMuchItCostRef} className="pricing">
          <div className="container">

            <div className="section-title">
              {homeLangData.titolo3prezzo ? (<h2>{homeLangData.titolo3prezzo}</h2>) : (<></>)}
            </div>

            <div className="row">

              <div className="col-lg-4 pt-4 pt-lg-0 content d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <ul>
                  <li>
                    <div className="icon-box">
                      <div className="icon">
                        <i className="fas fa-address-book"></i>
                      </div>
                    </div>
                    <div>
                      {homeLangData.prezzo1 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.prezzo1 != undefined ? homeLangData.prezzo1 : "") }} />) : (<></>)}

                      {/* <h5>Crea il tuo profilo e tienilo costantemente aggiornato</h5>
                    <p style={{color: "black"}}>Con una scheda ricca di informazioni, dati e media, racconta la tua storia, la tua esperienza, i tuoi obiettivi. Aggiorna il tuo profilo in qualsiasi momento con le informazioni più recenti delle tue attività.</p> */}
                    </div>
                  </li>

                  <li>
                    <div className="icon-box">
                      <div className="icon">
                        <i className="far fa-envelope-open"></i>
                      </div>
                    </div>
                    <div>
                      {homeLangData.prezzo2 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.prezzo2 != undefined ? homeLangData.prezzo2 : "") }} />) : (<></>)}

                      {/* <h5>Contatti illimitati con i cori di tutto il mondo</h5>
                    <p style={{color: "black"}}>Conosci gruppi corali di diversi paesi, con tradizioni e usanze diverse, con nuovi repertori.
                     Viaggia e ospita tutto l’anno senza alcun limite, semplicemente trovando un accordo con i tuoi interlocutori.</p> */}
                    </div>
                  </li>

                  <li>
                    <div className="icon-box">
                      <div className="icon">
                        <i className="far fa-calendar-check"></i>
                      </div>
                    </div>
                    <div>
                      {homeLangData.prezzo3 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.prezzo3 != undefined ? homeLangData.prezzo3 : "") }} />) : (<></>)}

                      {/* <h5>Cerca il tuo scambio ideale</h5>
                    <p style={{color: "black"}}>Decidi se cercare i cori per tipologia, per area geografica, per repertorio, disponibilità delle date, o altre caratteristiche per te importanti.</p> */}
                    </div>
                  </li>

                </ul>
              </div>


              <div className="col-lg-4 pt-4 pt-lg-0 justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                <div className="box featured">
                  {homeLangData.priceCentral ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.priceCentral != undefined ? homeLangData.priceCentral : "") }} />) : (<></>)}

                  {/* <h3>Abbonamento annuale</h3>
                
                    <p>Crea il tuo profilo e tienilo costantemente aggiornato</p><br></br>
                    <p>Contatti illimitati con i cori di tutto il mondo</p><br></br>
                    <p >Cerca il tuo scambio ideale</p><br></br>
                    <p>Una comunità di appassionati come te</p><br></br>
                    <p>Proponi le tue produzioni o cerca nuovi interpreti</p><br></br> */}

                  <div className="btn-wrap">
                    <a className="btn-buy" onClick={() => { setFeedback(true) }}>
                      {homeLangData.buttonPrezzo ? (homeLangData.buttonPrezzo) : (<></>)}
                    </a>
                  </div>
                </div>
              </div>


              <div className="col-lg-4 pt-4 pt-lg-0 content d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <ul>

                  <li>
                    <div style={{ textAlign: "right" }}>
                      {homeLangData.prezzo4 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.prezzo4 != undefined ? homeLangData.prezzo4 : "") }} />) : (<></>)}

                      {/* <h5 >Una comunità di appassionati come te</h5>
                <p >Unisciti ad una comunità che ama la musica corale, che ha la tua stessa passione. Diversi livelli di verifica aumentano la fiducia e sicurezza tra i partecipanti.</p> */}
                    </div>
                    <div className="icon-box">
                      <div className="icon">
                        <i className="fas fa-users"></i>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div style={{ textAlign: "right" }}>
                      {homeLangData.prezzo5 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.prezzo5 != undefined ? homeLangData.prezzo5 : "") }} />) : (<></>)}

                      {/* <h5>Proponi le tue produzioni o cerca nuovi interpreti</h5>
                <p style={{textAlign: "right", color: "black"}}>Metti in evidenza le tue più importanti produzioni, quelle che ti piacerebbe far scoprire a nuovi pubblici.
                     Se sei un organizzatore di eventi artistici cerca nuovi interpreti idee e proposte che fanno al caso tuo.</p> */}
                    </div>
                    <div className="icon-box">
                      <div className="icon">
                        <i className="fas fa-bullhorn"></i>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div style={{ textAlign: "right" }}>
                      {homeLangData.prezzo6 ? (<span dangerouslySetInnerHTML={{ __html: converter.makeHtml(homeLangData.prezzo6 != undefined ? homeLangData.prezzo6 : "") }} />) : (<></>)}

                      {/* <h5>Assistenza</h5>
                <p style={{textAlign: "right", color: "black"}}>Contattaci se incontri difficoltà nell’utilizzo della piattaforma o con i tuoi scambi.</p> */}
                    </div>
                    <div className="icon-box">
                      <div className="icon">
                        <i className="fas fa-at"></i>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>



            </div>
          </div>
        </section>

        <section ref={contactsRef} className="contact">
          <div className="container">

            <div className="section-title">
              <h2>{t('contatti')}</h2>
            </div>

            <div className="row" data-aos="fade-up">

              <div className="col-lg-12 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email</h3>
                  <p><a href="mailto:info@choirex.com">info@choirex.com</a></p>
                </div>
              </div>

            </div>


          </div>
        </section>

        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-4">
                  <div className="footer-info">
                    <h3>Choirex</h3>
                    <div className="social-links mt-3">
                      <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                      <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                      <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                      <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                      <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="footer-info">
                    <h4>{t('feedbackquestionario')}</h4>
                    <p>{t('aiutaci')}</p></div>

                  <div className="d-flex">

                    <button type="button" className="btn btn-outline-info" onClick={() => { setFeedback1(true) }}>
                      {t('feedback')}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="container">
            <div className="copyright">&copy; Copyright <strong><span>Choirex</span></strong>. All Rights Reserved</div>
            <div className="credits">
              Designed by <a href="https://bootstrapmade.com/">Radix</a>
            </div>
          </div>
        </footer>

        <a href="#" className="back-to-top"><i className="ri-arrow-up-line"></i></a>
        <div id="preloader"></div>

      </main>
      <Modal fluid toggle={toggle} isOpen={feedback} style={{ paddingBottom: '30px' }}>
        <Card className="no-transition pl-0 pr-0 bl-0 bb-0 pb-0 mb-20 mt-0">
          <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="bg-primary text-center"><span className="text-white text-uppercase">{t('prezzodesiderato')}</span></CardHeader>
          <CardBody >
            <Survey.Survey style={{ textAlign: 'left' }} model={modelPrice} onComplete={onSurveyComplete} />
          </CardBody>
        </Card>
      </Modal>
      <Modal fluid toggle={toggle1} isOpen={feedback1} style={{ paddingBottom: '30px' }}>
        <Card className="no-transition pl-0 pr-0 bl-0 bb-0 pb-0 mb-20 mt-0">
          <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="bg-primary text-center"><span className="text-white text-uppercase">{t('raccontaci')}</span></CardHeader>
          <CardBody >
            <Survey.Survey style={{ textAlign: 'left' }} model={model} css={myCss} onComplete={onSurveyComplete} />
          </CardBody>
        </Card>
      </Modal>
    </div>
  );
}
