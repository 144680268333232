import React from 'react';

let _uri
let _url_img = 'https://choirex-backend-heroku.herokuapp.com'
console.log('with PORT', process.env)

let port = "1337", host = "127.0.0.1", protocol = "http";

if (process.env.REACT_APP_PORT != undefined ){
  port = process.env.REACT_APP_PORT
}

if (process.env.REACT_APP_HOST != undefined ){
  host = process.env.REACT_APP_HOST
}

if (process.env.REACT_APP_PROTOCOL != undefined ){
  protocol = process.env.REACT_APP_PROTOCOL
}

if (process.env.NODE_ENV === "development") {
  _uri = `${protocol}://${host}:${port}/graphql`
  _url_img = `${protocol}://${host}:${port}`
} else if (process.env.NODE_ENV === 'production') {
  _uri = "https://choirex-backend-heroku.herokuapp.com/graphql"
  _url_img = "https://choirex-backend-heroku.herokuapp.com"
} 

export const uri = _uri
export const url_img = _url_img
