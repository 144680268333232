import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Card, CardImg, CardText, CardBody, CardHeader,
  CardTitle, CardSubtitle, Button, Container,
  Input, InputGroup, InputGroupAddon,
  Form, Col, Row, FormGroup, Label, FormText, CustomInput,
  Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  ModalHeader,
  ModalBody,
  ModalFooter, InputGroupText, Popover
} from 'reactstrap';
import { cpuUsage } from 'process';
import 'leaflet/dist/leaflet.css'
import { Map, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'
import L, { LatLngTuple } from 'leaflet';
import { redIcon, blueIcon, themedIcon } from './MapUtilities'
import { FaSearch } from "react-icons/fa";
import { Header } from "../../components/Header"
import { uri, url_img } from '../../backend';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { useHistory } from 'react-router-dom';
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import * as Survey from 'survey-react';
import "survey-react/survey.css";
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

var json = require('../../model.json');
if (navigator.language !== 'it-IT') {
  json.locale = "en"
}
const model = new Survey.Model(json);

var showdown = require('showdown')

const iconMarkup = renderToStaticMarkup(
  <i style={{ textShadow: '1px 1px 6px #444444ff' }} className="text-success fa fa-map-marker-alt fa-3x" />
);
const customMarkerIcon = divIcon({
  html: iconMarkup,
  iconAnchor: [14, 37],
  popupAnchor: [0, -37]
});

const client = new ApolloClient({
  uri: uri,
  cache: new InMemoryCache()
});
console.log("stage", process.env.NODE_ENV)
// const defaultLatLng: LatLngTuple = [39.217162, 9.113405];
const defaultLatLng: LatLngTuple = [45.4668, 9.1905];

const onSurveyComplete = async (survey: any, options: any) => {

  console.log('received data: ', survey.valuesHash);
  const result = await emailjs.send('service_choirex_dev', 'template_5tdtjr8', { message: JSON.stringify(survey.valuesHash) }, "user_xEBz3J4puqVSrggiPM0cx")
  console.log('with result: ', result);

}
export const Platform = () => {
  const [choirs, setChoirs] = React.useState([] as any)
  const [login, setLogin] = React.useState(false)
  const [feedback, setFeedback] = React.useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false);
  const { t, i18n } = useTranslation('platform', { useSuspense: false });

  var myCss = {
    matrix: { root: "table table-striped matrixtable" },
  };
  React.useEffect(() => {
    console.log('in start effect')

    const logged = localStorage.getItem('logged');
    // if (logged == undefined || logged == "false") setLogin(true)
    const _ = (
      async () => {
        const result = await client.query({
          query: gql`
              query test{
                choirs{
                  id
                  nome
                  indirizzo
                  latitudine
                  longitudine
                  images{
                    name
                    url
                  }
                }
              }
          `
        })
        console.log('after result: ', result)
        setChoirs(result.data.choirs);
        console.log('after result:1 ', choirs)

      }
    )()
    return () => { }

  }, [])

  const options = [
    { value: 'misto', label: `${t('misto')}` },
    { value: 'voci_pari_maschili', label: `${t('voci_pari_M')}` },
    { value: 'voci_pari_femminili', label: `${t('voci_pari_F')}` },
    { value: 'voci_bianche', label: `${t('voci_bianche')}` },
    { value: 'giovanili', label: `${t('giovanili')}` },

  ]

  const coristi_options = [
    { value: 'lt_10', label: `${t('meno_di_10')}` },
    { value: 'gt_10_lt_25', label: `${t('tra_10_e_25')}` },
    { value: 'gt_25_lt_40', label: `${t('tra_25_e_40')}` },
    { value: 'gt_40', label: `${t('oltre_40')}` },
  ]

  const repertorio_prevalente_options = [
    { value: 'polifonia_sacra_e_profana_di_varie_epoche_e_stili', label: `${t('polifonia_sacra_e_profana_di_varie_epoche_e_stili')}` },
    { value: 'musica_popolare', label: `${t('musica_popolare')}` },
    { value: 'lirica', label: `${t('lirica')}` },
    { value: 'musica_liturgica', label: `${t('musica_liturgica')}` },
    { value: 'musica_da_film', label: `${t('musica_da_film')}` },
    { value: 'pop', label: `${t('pop')}` },
    { value: 'gospel', label: `${t('gospel')}` },
    { value: 'nessun_repertorio_prevalente', label: `${t('nessun_repertorio_prevalente')}` },
    { value: 'altro', label: `${t('altro')}` }
  ]

  const tipologia_di_scambio_options = [
    { value: 'reciproco', label: `${t('reciproco')}` },
    { value: 'non_reciproco', label: `${t('non_reciproco')}` },
    { value: 'artistico', label: `${t('artistico')}` }
  ]

  const steps = [
    {
      selector: '.first-step',
      content: <p>{t('step1')}</p>
      // content: <p>Cerca il tuo <span className="text-success"><strong>scambio ideale</strong></span> utilizzando i filtri di ricerca</p>
    },
    {
      selector: '.second-step',
      content: <p>{t('step2')}</p>
      // content: <p>Visualizza i <span className="text-success"><strong>risultati</strong></span> della tua ricerca</p>,
    },
    {
      selector: '.third-step',
      content: <p>{t('step3')}</p>
      // content: <p>Conosci il <span className="text-success"><strong>coro</strong></span> che ti interessa</p>,
    },
    // {
    //   selector: '.fourth-step',
    //   content: <p>Aggiungi il <span className="text-success"><strong>tuo coro</strong></span></p>
    // },
    {
      selector: '.fifth-step',
      content: <p></p>,
      action: () => {
        const target=document.querySelector(".tour")
        enableBodyScroll(target as any);
        history.push("/miocoro")}
    },
    // ...
  ];


  const history = useHistory();

  const disableBody = (target: any) => disableBodyScroll(target)
  const enableBody = (target: any) => enableBodyScroll(target)
  const toggle = () => setFeedback(!feedback);

  return (
    <>
      <Tour
      className="tour"
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        accentColor="#00A0A4"
        maskSpace={32}
        rounded={32}
      />
      <Header />
      <Container fluid style={{ backgroundColor: '#CCCCCC', paddingTop: 10, minHeight: '100vh' }}>
        <Row>
          <Col style={{ minHeight: '100vh' }} xs='12' md='6'>

            <Card className="no-transition mt-4">
              <CardBody>
                <div className="first-step">
                  <CardHeader><h6>{t('dove_andare')}</h6></CardHeader>
                  <form>
                    <FormGroup>
                      <Input
                        type="text"
                        name="luogo"
                        id="luogo"
                        placeholder={t('cerca_localita')}
                      />
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={6}>
                        <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('cerca_n_coristi')} isMulti options={coristi_options} />
                      </Col>
                      <Col sm={6}>
                        <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('cerca_t_voci')} options={options} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12}>
                        <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('cerca_repertorio')} options={repertorio_prevalente_options} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12}>
                        <CreatableSelect styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} placeholder={t('cerca_t_scambio')} options={tipologia_di_scambio_options} />
                      </Col>
                    </FormGroup>
                  </form>
                </div>
                {/* <h6 className="second-step">{t('risultati_ricerca')}</h6> */}
                <Row>
                  {choirs.map((choir: any) => (
                    <>
                      {choir.id == 16 ? (
                        <Col md={6} style={{ minHeight: '50vh' }} className="third-step">
                          <a href={`/coro/${choir.id}`}>
                            <Card className="mb-2" style={{ minHeight: '50vh' }} key={choir.id} >
                              <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, minHeight: '10vh' }} className="bg-success">
                                <CardTitle className="text-white"><h6>{choir.nome}</h6></CardTitle>
                              </CardHeader>
                              {choir.images[0] ? (
                                <CardImg style={{ borderRadius: 0, minHeight: '30vh' }} top width="300" height="150" src={`${url_img}${choir.images[0].url}`} alt="Card image cap" />
                              ) : (
                                  <CardImg style={{ borderRadius: 0, minHeight: '30vh' }} top width="300" height="150" src="https://via.placeholder.com/633x400" alt="Card image cap" />
                                )}
                              <CardBody>
                                <span className="font-weight-light">{choir.indirizzo}</span>
                              </CardBody>
                            </Card>
                          </a>
                        </Col>
                      ) : (
                          <Col md={6} style={{ minHeight: '50vh' }}>
                            <a href={`/coro/${choir.id}`}>
                              <Card className="mb-2" style={{ minHeight: '50vh' }} key={choir.id} >
                                <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, minHeight: '10vh' }} className="bg-success">
                                  <CardTitle className="text-white"><h6>{choir.nome}</h6></CardTitle>
                                </CardHeader>
                                {choir.images[0] ? (
                                  <CardImg style={{ borderRadius: 0, minHeight: '30vh' }} top width="300" height="150" src={`${url_img}${choir.images[0].url}`} alt="Card image cap" />
                                ) : (
                                    <CardImg style={{ borderRadius: 0, minHeight: '30vh' }} top width="300" height="150" src="https://via.placeholder.com/633x400" alt="Card image cap" />
                                  )}
                                <CardBody>
                                  <span className="font-weight-light">{choir.indirizzo}</span>
                                </CardBody>
                              </Card>
                            </a>
                          </Col>)}</>
                  ))}
                </Row>

              </CardBody>
            </Card>
          </Col>
          {/* <Col > */}
          <Col style={{ height: '600px', width: '100%' }} xs='12' md='6'>
            <Card className="no-transition mt-4">
              <CardHeader><h6>{t('cerca')}</h6></CardHeader>
              <CardBody style={{ height: '600px', width: '100%', position: 'relative' }} className="mb-4">
                <Map id="TooltipExample" center={L.latLng(defaultLatLng)} zoom={5} style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                  <TileLayer
                    // url="https://maps.omniscale.net/v2/{id}/style.grayscale/{z}/{x}/{y}.png"
                    url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  />
                  {choirs.map((choir: any, index: any) => (
                    <>
                      {choir.latitudine && choir.longitudine ? (
                        <Marker
                          // onClick={toggle(choir.nome)}
                          key={`marker-${index}`}
                          icon={customMarkerIcon}
                          position={L.latLng(choir.latitudine, choir.longitudine)}
                          draggable={false}
                        >
                          <Popup><Button className="btn-link" color="success" onClick={() => {
                            history.push(`/coro/${choir.id}`)
                          }}>{choir.nome}</Button></Popup>
                        </Marker>) :
                        (<></>)}
                    </>
                  ))}
                </Map>
              </CardBody>
            </Card>
          </Col>
          {/* </Col> */}
        </Row>
        <Modal fluid isOpen={login}>
          <Card className="no-transition pl-0 pr-0 bl-0 bb-0 pb-0 mb-0 mt-0">
            <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="bg-primary text-center"><span className="text-white text-uppercase">{t('login_piatt')}</span></CardHeader>
            <CardBody >
              <CardTitle className="text-center font-weight-bold">{t('inserisci_cred')}</CardTitle>
              <Form className="form">
                <Col>
                  <FormGroup row>
                    <Label sm={3}>Email</Label>
                    <Col sm={9}>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="myemail@email.com"
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup row>
                    <Label for="examplePassword" sm={3}>Password</Label>
                    <Col sm={9}>
                      <Input
                        type="password"
                        name="password"
                        id="examplePassword"
                        placeholder="********"
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Container>
                  <Row>
                    <Col md={6}>
                      <Button block color="primary" onClick={() => {
                        localStorage.setItem('logged', "true");
                        setLogin(false)
                      }
                      }>{t('accedi')}</Button>
                    </Col>
                    <Col md={6}>
                      <Button block color="primary" disabled>{t('registrati')}</Button>
                    </Col>
                  </Row>
                </Container>
                <Button block color="link">{t('pw_dimenticata')}</Button>
              </Form>
            </CardBody>
          </Card>
        </Modal>
      </Container>
      {/* <Modal fluid toggle={toggle} isOpen={feedback} style={{ paddingBottom: '30px' }}>
        <Card className="no-transition pl-0 pr-0 bl-0 bb-0 pb-0 mb-20 mt-0">
          <CardHeader style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="bg-primary text-center"><span className="text-white text-uppercase">{t('raccontaci')}</span></CardHeader>
          <CardBody >
            <Survey.Survey style={{ textAlign: 'left' }} model={model} css={myCss} onComplete={onSurveyComplete} />
          </CardBody>
        </Card>
      </Modal> */}

      <div className="shadow-lg" style={{ position: 'fixed', borderRadius: 32, bottom: 10, right: 10, zIndex: 10000, backgroundColor: 'transparent' }}>
        <Button size="lg" className="shadow-lg btn-round mb-0 mt-0 " style={{ height: 60 }} color="success" onClick={() => {
          console.log('pressed');
          setIsTourOpen(true);
        }}>{t('tour')}</Button>
      </div>
      {/* <div className="shadow-lg" style={{ position: 'fixed', borderRadius: 32, bottom: 10, right: 200, zIndex: 10000, backgroundColor: 'transparent' }}>
        <Button size="lg" className="shadow-lg btn-round mb-0 mt-0 " style={{ height: 60 }} color="success" onClick={() => {
          console.log('pressed');
          setFeedback(true);
        }}>{t('feedback')}</Button>
      </div> */}
    </>
  )
}