export default `
query getChoirAndReservation($id: ID!){
  choir(id: $id) {
    id
    nome
    email
    telefono
    anni_attivita
    numero_cantori
    images{
      name
      url
      size
      formats
    }
    videos{
      name
      url
      size
    }
    numero_concerti
    indirizzo
    latitudine
    longitudine
    sito_web
    instagram
    facebook
    flickr
    youtube
    localized_data{
      language
      nostra_storia
      description{
        aggettivi
        commento
        cantori
        valori_ideali
      }
      viaggio
      proposta_scambio
      tipologia_coro
      tipologia_voci
      tipo_scambio{tipologia_scambio}
      destinazioni{destinazioni_preferite}
      alloggio{alloggio}
      vitto{vitto}
      date_ospitato{
        mese
        anno
      }
      date_ospitare{
        mese
        anno
      }
      trasporto{trasporto}
      luoghi_concerti{luoghi_concerti}
      attrezzatura{attrezzatura}
      servizi_aggiuntivi{servizi_aggiuntivi}
      lingue_parlate{lingue_parlate}
    }    
    reservations{
      id
      from
      to
      type
    }


  }

  reservations{
    id
    from
    to
    type
    choir{
      id
    }
   }
}`